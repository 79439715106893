/* eslint-disable no-console */
import {register} from 'register-service-worker'
import {DataStore} from "aws-amplify";

// if (process.env.NODE_ENV === 'production') {

register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
        console.log(
            'App is being served from cache by a service worker.\n' +
            'For more details, visit https://goo.gl/AFskqB'
        )
        // let filter1 = 'brand';
        // let filter2 = 'modelnumber';
        DataStore.start().then(r => {
            console.log(r);
        });
    },
    registered() {
        console.log('Service worker has been registered.')
    },
    cached() {
        console.log('Content has been cached for offline use.')
    },
    updatefound(event) {

    },
    updated(event) {
        console.log('New content is available; please refresh.')
    },
    offline() {
        console.log('No internet connection found. App is running in offline mode.')
    },
    error(error) {
        console.error('Error during service worker registration:', error)
    },
})

