import { createRouter, createWebHistory } from '@ionic/vue-router';
// import HomePage from '../views/HomePage.vue';
import ModelPage from '../views/ModelPage.vue';
// import AdminHomePage from '../views/AdminHomePage.vue';
// import AdminModelPage from '../views/AdminModelPage.vue';
// import ItemPage from '../views/ItemPage.vue';

const routes = [
    {
        path: '/',
        redirect: '/footcare/home'
    },
    {
        path: '/admin',
        name: 'AdminHomePage',
        component: () => import("../views/AdminHomePage.vue")
    },
    {
        path: '/user-list',
        name: 'AdminUserPage',
        component: () => import("../views/AdminUserPage.vue")
    },
    {
        path: '/modelslist',
        name: 'AdminModelPage',
        component: () => import("../views/AdminModelPage.vue")
    },
    {
        path: '/item/:item/:table',
        name: 'ItemPage',
        component: () => import("../views/ItemPage.vue")
    },
    {
        path: '/fittr/home',
        name: 'HomePage',
        component: () => import("../views/HomePage.vue")
    },
    {
        path: '/footcare/home',
        name: 'HomePage',
        component: () => import("../views/HomePage.vue")
    },
    {
        path: '/osb/measurements/:category',
        name: 'MeasurementsPage',
        component: () => import("../views/MeasurementsPage.vue")
    },
    {
        path: '/osb/lasts/:data',
        name: 'LastPage',
        component: () => import("../views/LastPage.vue"),
        props: true,
    },
    {
        path: '/osb/lasttypes/:category',
        name: 'LasttypesPage',
        component: () => import("../views/LasttypesPage.vue")
    },
    {
        path: '/osa/models/:models:category',
        name: 'ModelPageOsa',
        component: ModelPage
    },
    {
        path: '/osb/models-by-lastgroup/:models',
        name: 'ModelPageOsbByLastGroup',
        component: () => import("../views/ModelPage.vue"),
        props: true,
    },
    {
        path: '/osb/models/:models',
        name: 'ModelPageOsb',
        component: () => import("../views/ModelPage.vue")
    },
    {
        path: '/order/:model',
        component: () => import("../views/OrderPage.vue"),
        name: 'Order',
        props: true,
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router