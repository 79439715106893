// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { ActiveModels, DevFittrOrder, DevFittrModel, DevFittrLastgroup, DevFittrClosing, DevFittrColor, DevFittrStyle, DevFittrHeight, DevFittrSpec, DevFittrLast, DevFittrLastV1, DevFittrPurchaseprice, DevPurchasePriceLimit, DevFootcareModel, FootcareModelV0, FootcarePriceLevel, FootcareSupplier, FootcareHealthcareProvider, DevFootcareStyle, DevFootcareClosing, DevFootcareInstep, DevFootcareHeight } = initSchema(schema);

export {
  ActiveModels,
  DevFittrOrder,
  DevFittrModel,
  DevFittrLastgroup,
  DevFittrClosing,
  DevFittrColor,
  DevFittrStyle,
  DevFittrHeight,
  DevFittrSpec,
  DevFittrLast,
  DevFittrLastV1,
  DevFittrPurchaseprice,
  DevPurchasePriceLimit,
  DevFootcareModel,
  FootcareModelV0,
  FootcarePriceLevel,
  FootcareSupplier,
  FootcareHealthcareProvider,
  DevFootcareStyle,
  DevFootcareClosing,
  DevFootcareInstep,
  DevFootcareHeight
};