import iconSet from 'quasar/icon-set/fontawesome-v5.js'
// import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
// import Fastclick from '@quasar/fastclick';
import './theme/quasar.prod.css';
import Notify from 'quasar/src/plugins/Notify.js';import TouchPan from 'quasar/src/directives/TouchPan.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
// To be used on app.use(Quasar, { ... })
export default {
  config: {
    framework: {cssAddon: true},
    brand: {
      primary: '#4b4b5d',
      secondary: '#d6a177',
      accent: '#afafaf',
      dark: '#1d1d1d',
      positive: '#21BA45',
      negative: '#C10015',
      info: '#31CCEC',
      warning: '#ff9966'
    },
    notify: { /* look at QuasarConfOptions from the API card */ }
  },
  directives: {
    TouchPan, ClosePopup
  },
  plugins: {
    Notify
  },
  framework: {cssAddon: true},
  iconSet: iconSet,
  // Fastclick:Fastclick
}