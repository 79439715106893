/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://rsnb2baafvgkpf7352lktm3nea.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-detj6ap62zdlxg3r2kn3auhhiy",
    "aws_cognito_identity_pool_id": "eu-west-1:1492486f-e650-4410-959c-931a14ae51b8",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ldtQG01Ks",
    "aws_user_pools_web_client_id": "4tad4t2lj9tsesohija1b8dvur",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GENDER",
        "GIVEN_NAME",
        "PICTURE",
        "UPDATED_AT"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
