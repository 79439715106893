import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index.js'

import {
    IonItem,
    IonicVue,
    IonList,
    IonHeader,
    IonButtons,
    IonButton,
    IonBackButton,
    IonMenuToggle,
    IonPage,
    IonCard,
    IonCardHeader,
    IonCardContent,
    IonCardTitle,
    IonCardSubtitle,
    IonGrid,
    IonRow,
    IonCol,
    IonLabel,
    IonContent,
    IonToolbar,
    IonTitle
} from '@ionic/vue';

import Amplify, {Auth} from 'aws-amplify';

import awsmobile from './aws-exports.js';

Amplify.configure(awsmobile);
Amplify.configure({
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'eu-west-1:6b946439-f55a-4d6d-b69f-4a4e48472e66', // REQUIRED - Amazon Cognito Region
        region: 'eu-west-1', // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'eu-west-1', // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-west-1_70bmL721z', // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '3061lm42b3p4ijfkoc4n0lmlra', // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false, // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // OPTIONAL - Hosted UI configuration
        oauth: {}
    },
    Analytics: {
        disabled: true,
    },
});
// You can get the current config object
Auth.configure();

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables - copied from existing project */
import './theme/variables.css';

import Quasar from 'quasar/src/vue-plugin.js';

import quasarUserOptions from './quasar-user-options'

import {defineCustomElements} from '@ionic/pwa-elements/loader';

defineCustomElements(window).then();

import VueZoomer from 'vue-zoomer';
import VueSocialSharing from 'vue-social-sharing'

import './registerServiceWorker';

const app = createApp(App).use(Quasar, quasarUserOptions)
    .use(IonicVue)
    .use(router)
    .use(VueZoomer)
    .use(VueSocialSharing);

app.component('ion-content', IonContent);
app.component('ion-page', IonPage);
app.component('ion-header', IonHeader);
app.component('ion-buttons', IonButtons);
app.component('ion-back-button', IonBackButton);
app.component('ion-button', IonButton);
app.component('ion-menu-toggle', IonMenuToggle);
app.component('ion-card', IonCard);
app.component('ion-card-header', IonCardHeader);
app.component('ion-card-title', IonCardTitle);
app.component('ion-card-content', IonCardContent);
app.component('ion-card-subtitle', IonCardSubtitle);
app.component('ion-grid', IonGrid);
app.component('ion-row', IonRow);
app.component('ion-col', IonCol);
app.component('ion-item', IonItem);
app.component('ion-list', IonList);
app.component('ion-label', IonLabel);
app.component('ion-toolbar', IonToolbar);
app.component('ion-title', IonTitle);

router.isReady().then(() => {
    app.mount('#app');
});