import {reactive, ref} from "vue";
import router from "../router";
import {DevFittrModel, DevFootcareModel, FootcareModelV0, DevFittrOrder, ActiveModels} from "../models";
import {SortDirection, DataStore} from "aws-amplify";
import {Network} from '@capacitor/network';
import * as DB from "../models";

let dbModel, dbOsbModel, dbOsaModel, filterCategory, filter1, filter2;
dbOsbModel = DevFittrModel;
dbOsaModel = DevFootcareModel;


if (router.currentRoute.value.path.includes("osa") && router.currentRoute.value.path.includes("models")) {
    dbModel = dbOsaModel;
    filterCategory = "category";
    filter1 = "modelnumber";
    filter2 = "colornumber";
} else if (router.currentRoute.value.path.includes("osb") && router.currentRoute.value.path.includes("models")) {
    dbModel = DevFittrModel;
    filterCategory = "category";
    filter1 = "brand";
    filter2 = "modelnumber";
}

const attributes = ref();
attributes.value = {};

// (async () => {
//     user.value = await Auth.currentAuthenticatedUser({
//         bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
//     }).then(user => attributes.value = user.attributes).catch(err => console.log(err));
//     console.log(attributes);
//     DataStore.configure({
//         syncExpressions: [
//             await syncExpression(DevFittrOrder, () => {
//                 return order => order.userName('eq', attributes.value.email);
//             }),
//         ]
//     });
// })()

const state = reactive({
    submittedOrders: [],
    archivedOrders: [],
    nextOptions: [],
    models: [],
    footData: [],
    filters: {
        styles: [],
        height: [],
        closings: [],
        priceLevel: [],
        healthcareProviders: [],
        suppliers: []
    },
    selectedFilters: [],
    activeModel: [],
    brands: [],
    shareUrl: 'https://test-app.footcaremaatschoenen.info/osb/models-by-lastgroup/',
    imagePath: "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/",
    networkStatus: '',
})

const methods = {
    async filter(propName, it) {
        const data = {criteria: []}

        const val = state.filters[propName].indexOf(it.id);
        if (val === -1) {
            state.filters[propName].push(it.id);
        } else {
            state.filters[propName].splice(val, 1);
        }

        Object.entries(state.filters).map(([k, v]) => {
            v.forEach(it => {
                data.criteria.push({
                    'fieldId': k,
                    'predicate': 'contains',
                    'value': it
                })
            })
        })
        const myResult = this.fetchData(data)
        console.log(myResult)
        state.models = await myResult;
    },
    fetchData: async props => {
        /*
        More configurable wrapper for Datastore.query calls
        @param props: {model: Model, criteria: [{fieldId, predicate, value}]}.
         */
        try {
            let criteria;
            if (props.criteria && typeof props.criteria === 'object') {
                criteria = c => {
                    props.criteria.forEach(item => {
                        const predicate = item.predicate || 'contains';
                        c[item.fieldId](predicate, item.value);
                    });
                    return c;
                };
            } else {
                criteria = props.criteria;
            }
            return await DataStore.query(ActiveModels, criteria);
        } catch (e) {
            throw new Error(e);
        }
    },
    unique(array, propertyName) {
        return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
    },
    modelExists(arr, modelID) {
        return arr.some(function (el) {
            return el.modelID === modelID;
        });
    },
    async getSubmittedOrders(user) {
        if (user) {
            attributes.value = user.attributes;
        }
        // let result = ref([]);
        // let result2 = ref([]);
        await DataStore.observeQuery(DB["DevFittrOrder"], c => c.status('contains', 'submitted'), {
            sort: s => s.submittedAt(SortDirection.DESCENDING)
        }).subscribe(snapshot => {
            const {items, isSynced} = snapshot;
            state.submittedOrders = items;
            console.log(`[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
        });
        // await DataStore.observeQuery(DB["DevCareOrder"], c => c.status('contains', 'processed'), {
        //     sort: s => s.submittedAt(SortDirection.DESCENDING)
        // }).subscribe(snapshot => {
        //     const {items, isSynced} = snapshot;
        //     result2.value = items;
        //     console.log(`[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
        // });
        // result.value = result.value.concat(result2.value)
        // console.log(result.value);
        // state.submittedOrders = [];
        // state.submittedOrders = result.value;
        return state.submittedOrders
    },
    async getArchivedOrders(user) {
        state.archivedOrders = await DataStore.query(DB['DevFittrOrder'], c => c.userName('contains', user ? user : attributes.value.email).status('contains', 'archived'), {
            sort: s => s.submittedAt(SortDirection.DESCENDING)
        });
    },
    async searchSubmittedOrders(value) {
        if (value === "") {
            await this.getSubmittedOrders();
        } else {
            const lowerCased = value.replaceAll(" ", "").toLowerCase();
            state.submittedOrders = await DataStore.query(DB['DevFittrOrder'], c => c.userName('contains', attributes.value.email).search("contains", lowerCased))
        }
    },
    async initAppGlobals() {
        Network.addListener('networkStatusChange', status => {
            console.log('Network status changed', status);
            state.imagePath = status.connected ? window.location.origin + "/img/content/" : "https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/"
        });
        return await Network.getStatus();
    },
    async getModels() {
        return await DataStore.query(router.currentRoute.value.path.includes("osa") ? dbOsaModel : dbOsbModel, c =>
            c.category("contains", router.currentRoute.value.params.category), {
            sort: s => s[router.currentRoute.value.path.includes("osa") ? 'modelnumber' : 'brand'](SortDirection.ASCENDING)[[router.currentRoute.value.path.includes("osa") ? 'colornumber' : 'modelnumber']](SortDirection.ASCENDING)
        })
    },
    async filterModels(paramName, filterValue) {
        let result = [];
        let tempList = await DataStore.query(router.currentRoute.value.path.includes("osa") ? dbOsaModel : dbOsbModel, c =>
            c.category("contains", router.currentRoute.value.params.category), {
            sort: s => s[filter1](SortDirection.ASCENDING)[filter2](SortDirection.ASCENDING)
        });
        console.log(tempList)
    },
    async findUniqueModelValue(column) {
        let models = this.getModels();
        let lookup = {};
        let result = [];
        for (let model, i = 0; model = models[i++];) {
            let items = model[column];
            if (items !== undefined) {
                items = JSON.stringify(items);
                items = items.replace("[", "");
                items = items.replace("]", "");
                items = items.replaceAll(" ", "");
                items = items.replaceAll('"', '');
                items = items.split(",");
            }
            if (Array.isArray(items)) {  //in case items is a array, we need an extra loop
                for (let item, j = 0; item = items[j++];) {
                    if (!(item in lookup)) {
                        lookup[item] = 1;
                        if (item !== "na" && item !== "null") {
                            result.push(item);
                        }
                    }
                }
            } else if (!(items in lookup)) {
                lookup[items] = 1;
                if (items !== "na" && items !== "null") {
                    result.push(items);
                }
            }
        }
        return result;
    },
}

const dataMethods = {
    async findUniqueModelValue(column) {
        const models = await DataStore.query(FootcareModelV0)
        let lookup = {};
        let result = [];
        for (let model, i = 0; model = models[i++];) {
            let items = model[column];
            if (items !== undefined) {
                items = JSON.stringify(items);
                items = items.replace("[", "");
                items = items.replace("]", "");
                items = items.replaceAll(" ", "");
                items = items.replaceAll('"', '');
                items = items.split(",");
            }
            if (Array.isArray(items)) {  //in case items is a array, we need an extra loop
                for (let item, j = 0; item = items[j++];) {
                    if (!(item in lookup)) {
                        lookup[item] = 1;
                        if (item !== "na" && item !== "null") {
                            result.push(item);
                        }
                    }
                }
            } else if (!(items in lookup)) {
                lookup[items] = 1;
                if (items !== "na" && items !== "null") {
                    result.push(items);
                }
            }
        }
        return result;
    },
    async insertItems() {
        let models = await DataStore.query(FootcareModelV0);
        let newList = [];
        let category = [];
        // models.forEach(item => {
        //     temp.category = category;
        //     temp.lastgroup = item.lastgroup;
        //     temp.ballsize = item.ballsize;
        //     temp.ballwidth = item.ballwidth;
        //     temp.calculate_default_insole = item.calculate_default_insole;
        //     temp.brand = item.brand;
        //     temp.heelheight = item.heelheight;
        //     temp.heelinstepsize = item.heelinstepsize;
        //     temp.lastlength = item.lastlength;
        //     temp.lasttype = item.lasttype;
        //     temp.supplier = item.supplier;
        //     temp.toeheight = item.toeheight;
        //     temp.editors = item.editors;
        //     temp.reinforcement_types = [];
        //
        //     newList.push(item);
        // })
        // console.log(newList);
        /*let items = await this.findUniqueModelValue('colors')
        console.log(items);
        for (const item of items) {
            // await DataStore.save(
            //     new DevFtrColor({
            //         name: item,
            //     })
            // );
        }*/
        /*items = await this.findUniqueModelValue('specs')
        console.log(items);
        for (const item of items) {
            // await DataStore.save(
            //     new DevFtrSpec({
            //         name: item,
            //     })
            // );
        }*/

        // const stylos = await DataStore.query(DevFtrStyle);
        // const closios = await DataStore.query(DevFtrClosing);
        // const colorios = await DataStore.query(DevFtrColor);
        // const hits = await DataStore.query(DevFtrHeight);
        // const spcs = await DataStore.query(DevFtrSpec);
        for (const item of models) {
            // let search = '';
            // let lasttypes = item.lasttypes;
            // let lasts = []
            // lasttypes = lasttypes.replace("[", "");
            // lasttypes = lasttypes.replace("]", "");
            // lasttypes = lasttypes.replaceAll(" ", "");
            // lasttypes = lasttypes.replaceAll('"', '');
            // lasttypes = lasttypes.split(",");
            // for (let i = 0; i < lasttypes.length; i++) {
            //     let type = lasttypes[i];
            //     if (lasts.indexOf(type) === -1) {
            //         lasts.push(type);
            //     }
            // }
            // let styleList = item.styles;
            // let styles = [];
            // styleList = styleList.replace("[", "");
            // styleList = styleList.replace("]", "");
            // styleList = styleList.replaceAll(" ", "");
            // styleList = styleList.replaceAll('"', '');
            // styleList = styleList.split(",");
            // for (let i = 0; i < styleList.length; i++) {
            //     let index = stylos.find(element => element.name === styleList[i]);
            //     if (styles.indexOf(index.id) === -1) {
            //         index !== undefined ? styles.push(index.id) : null;
            //         index !== undefined ? search = search + JSON.stringify(index.name) : ''
            //     }
            // }
            //
            // let closeList = item.closings
            // let closings = [];
            // closeList = closeList.replace("[", "");
            // closeList = closeList.replace("]", "");
            // closeList = closeList.replaceAll(" ", "");
            // closeList = closeList.replaceAll('"', '');
            // closeList = closeList.split(",");
            // for (let i = 0; i < closeList.length; i++) {
            //     let index = closios.find(element => element.name === closeList[i]);
            //     if (closings.indexOf(index) === -1) {
            //         index !== undefined ? closings.push(index.id) : null;
            //         index !== undefined ? search = search + JSON.stringify(index.name) : ''
            //     }
            // }
            // console.log(closeList)
            // let colorList = item.colors
            // let colors = [];
            // colorList = colorList.replace("[", "");
            // colorList = colorList.replace("]", "");
            // colorList = colorList.replaceAll(" ", "");
            // colorList = colorList.replaceAll('"', '');
            // colorList = colorList.split(",");
            //
            // for (let i = 0; i < colorList.length; i++) {
            //     let index = colorios.find(element => element.name === colorList[i]);
            //     console.log(index);
            //     if (colors.indexOf(index) === -1) {
            //         index !== undefined ? colors.push(index.id) : null;
            //         index !== undefined ? search = search + JSON.stringify(index.name) : ''
            //     }
            // }

            // const original = await DataStore.query(Post, "123");
            //
            // await DataStore.save(
            //     Post.copyOf(original, updated => {
            //         updated.title = `title ${Date.now()}`;
            //     })
            // );
            //

            // let heightList = item.height
            // let heights = [];
            // heightList = heightList.replace("[", "");
            // heightList = heightList.replace("]", "");
            // heightList = heightList.replaceAll(" ", "");
            // heightList = heightList.replaceAll('"', '');
            // heightList = heightList.split(",");
            // for (let i = 0; i < heightList.length; i++) {
            //     let index = hits.find(element => element.name === heightList[i]);
            //     if (heights.indexOf(index) === -1) {
            //         index !== undefined ? heights.push(index.id) : null;
            //         index !== undefined ? search = search + JSON.stringify(index.name) : ''
            //     }
            // }
            //
            // let specList = item.specs
            // let specs = [];
            // specList = specList.replace("[", "");
            // specList = specList.replace("]", "");
            // specList = specList.replaceAll(" ", "");
            // specList = specList.replaceAll('"', '');
            // specList = specList.split(",");
            //
            // for (let i = 0; i < specList.length; i++) {
            //     let index = spcs.find(element => element.name === specList[i]);
            //     if (specs.indexOf(index) === -1) {
            //         index !== undefined ? specs.push(index.id) : null
            //         index !== undefined ? search = search + JSON.stringify(index.name) : ''
            //     }
            // }

            // let category = [];
            //
            // category.push(item.targetgroup);

            // let itemToSave = JSON.stringify(item);
            // itemToSave = JSON.parse(itemToSave);
            // let itemToSave = {
            //     modelnumber: item.modelnumber,
            //     filename: item.file_original,
            //     colornumber: item.colornumber,
            //     styles: item.styles,
            //     closings: item.closings,
            //     height: item.height,
            //     priceLevel: item.priceLevel,
            //     supplier: item.supplier,
            //     healthcareProvider: item.healthcareProvider,
            //     instep_type: item.instep_type,
            //     editors: [user.value.attributes.email],
            //     notes: item.notes,
            //     file_original: item.file_original,
            //     file_medium_sized: item.file_medium_sized,
            //     file_small_sized: item.file_small_sized,
            //     search: item.search
            // }
            //
            //
            // // search = search + JSON.stringify(itemToSave.modelnumber) +
            // //     JSON.stringify(itemToSave.modelname) +
            // //     JSON.stringify(itemToSave.colornumber) +
            // //     JSON.stringify(itemToSave.colorname) +
            // //     JSON.stringify(itemToSave.lastgroup) +
            // //     JSON.stringify(itemToSave.brand) +
            // //     JSON.stringify(itemToSave.supplier);
            //
            // let categoryOld = item.category;
            // console.log(categoryOld);
            // let category = categoryOld.split(",");
            // console.log(category);
            //
            // itemToSave['category'] = category;
            //
            // await DataStore.save(
            //     new FootcareModelV3(itemToSave)
            // )

            // colorList = colorList.replace("[", "");
            // colorList = colorList.replace("]", "");
            // colorList = colorList.replaceAll(" ", "");
            // colorList = colorList.replaceAll('"', '');
            // colorList = colorList.split(",");
            // const original = await DataStore.query(DevFootcareModelV2, "123");
            //
            // await DataStore.save(
            //     DevFootcareModelV2.copyOf(original, updated => {
            //         updated.category = category;
            //     })
            // );
            // for (let i = 0; i < category.length; i++) {
            //     let index = colorios.find(element => element.name === colorList[i]);
            //     console.log(index);
            //     if (colors.indexOf(index) === -1) {
            //         index !== undefined ? colors.push(index.id) : null;
            //         index !== undefined ? search = search + JSON.stringify(index.name) : ''
            //     }
            // }
        }
        console.log(newList);
    }
}


export default {
    state,
    methods,
    dataMethods,
}