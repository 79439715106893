<script setup>
// This starter template is using Vue 3 <script setup> SFCs
// Check out https://v3.vuejs.org/api/sfc-script-setup.html#sfc-script-setup
import {
  IonSearchbar,
  IonModal,
  IonBadge,
  IonImg,
  IonMenu,
  IonToolbar,
  IonHeader,
  useIonRouter,
  onIonViewDidEnter
} from '@ionic/vue';

import ModelPageFilter from "../components/ModelpageFilter"

import {
  useQuasar,
  QSkeleton,
  QToolbar,
  QSpace,
  QCarousel,
  QCarouselSlide,
  QIntersection,
  QImg,
} from 'quasar';

import {ref, inject} from "vue";
import {useRoute} from "vue-router";

import {
  DataStore, Predicates,
  SortDirection
} from "aws-amplify";

import {
  DevFootcareInstep,
  DevFittrHeight,
  DevFittrClosing,
  DevFittrColor,
  DevFittrStyle,
  DevFittrModel,
  FootcareModelV0,
  DevFootcareClosing,
  DevFootcareStyle,
  ActiveModels, DevFittrLastV1,
  FootcareHealthcareProvider, FootcarePriceLevel, FootcareSupplier
} from "../models";

import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';
import '@fortawesome/fontawesome-pro/css/light.min.css';
import '@fortawesome/fontawesome-pro/css/thin.min.css';
import '@fortawesome/fontawesome-pro/css/solid.min.css';

const models = ref([]);

const dbOsbModel = DevFittrModel;
const dbOsaModel = FootcareModelV0;

const router = useIonRouter();
const store = inject('store');

const dbModel = ref();
let category;
let filter1;
let filter2;

const favorites = ref([]);

const zoomed = ref(false);

const $q = useQuasar();

const selectedFilters = ref([]);

const styles = ref([]);
const closings = ref([]);
const colors = ref([]);
const heights = ref([]);
const insteps = ref([]);
const priceLevels = ref([]);
const healthcareProviders = ref([]);
const suppliers = ref([]);
const brands = ref([]);

const isOpenRef = ref(false);
const setOpen = (state) => isOpenRef.value = state;
const slide = ref('');

const activeModels = ref([]);
const activeModel = ref();
const contentType = ref();
const modelnumber = ref('');
const colornumber = ref('');
const zoomer = ref(null);
const lastgroups = ref([]);
const imageLoadingState = ref(false);
const filterLoadingState = ref(false);

const urlWeb = ref('https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/');

const route = useRoute();
const modellistLength = ref(30);

onIonViewDidEnter(() => {
  console.log('ModelPage did enter');
  if (route.path.includes("osa") && route.path.includes("models")) {
    dbModel.value = dbOsaModel;
    contentType.value = 'osa';
    category = 'category';
    filter1 = 'modelnumber';
    filter2 = 'colornumber';
  } else if (route.path.includes("osb") && route.path.includes("models")) {
    dbModel.value = dbOsbModel;
    contentType.value = 'osb';
    category = 'category';
    filter1 = 'brand';
    filter2 = 'modelnumber';
  }
  fetchData();
});

const fetchData = async () => {

  if (route.params.models) {

    models.value = JSON.parse(route.params.models);
    for (let i = 0; i < models.value.length; i++) {
      let tempModel = models.value[i];
      await DataStore.save(
          new ActiveModels(
              JSON.parse(JSON.stringify(tempModel))
          )
      );
    }
    DataStore.observeQuery(ActiveModels, Predicates.ALL, {
      sort: s => s[filter1](SortDirection.ASCENDING)[filter2](SortDirection.ASCENDING)
    }).subscribe( async snapshot => {
      const {items, isSynced} = snapshot;
      console.log(`[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
      if (items.length > (models.value.length/2) || items.length > 100) {
        store.state.models = items;
        // store.state.models = await DataStore.query(ActiveModels, Predicates.ALL, {
        //   sort: s => s[filter1](SortDirection.ASCENDING)[filter2](SortDirection.ASCENDING)
        // });
        imageLoadingState.value = true;
        filterLoadingState.value = true;
        await loadFilters();
      }
    });
  }
}


async function searchModels(value) {
  imageLoadingState.value = false;
  const capitalized = value.toString().toLowerCase().substr(0, 1).toUpperCase() + value.toString().toLowerCase().substr(1);
  if (value !== "" || value !== null) {

    // models.value.filter((model) => {
    //   console.log(model);
    //   if (model.search.includes(value.toString().toLowerCase())
    //   return model
    // })

    // models.value = await DataStore.query(DevFittrModel, c => c.or(
    //     c => c.modelnumber("contains", value.toString()).colornumber("contains", value.toString()).modelnumber("contains", capitalized).colornumber("contains", capitalized), {
    //       sort: s => s.modelnumber(SortDirection.ASCENDING).colornumber(SortDirection.ASCENDING)
    //     }
    // ));
  } else {
    models.value = await DataStore.query(ActiveModels, c =>
        c.category("contains", route.params.category), {
      sort: s => s.modelnumber(SortDirection.DESCENDING).colornumber(SortDirection.ASCENDING)
    });
  }
  setTimeout(() => {
    imageLoadingState.value = true;
  }, 500)
}

function findUniqueModelValue(column) {
  let lookup = {};
  let result = [];
  for (let model, i = 0; model = store.state.models[i++];) {
    let items = model[column];
    if (items !== undefined) {
      items = JSON.stringify(items);
      items = items.replace("[", "");
      items = items.replace("]", "");
      items = items.replaceAll(" ", "");
      items = items.replaceAll('"', '');
      items = items.split(",");
    }
    if (Array.isArray(items)) {  //in case items is a array, we need an extra loop
      for (let item, j = 0; item = items[j++];) {
        if (!(item in lookup)) {
          lookup[item] = 1;
          if (item !== "na" && item !== "null") {
            result.push(item);
          }
        }
      }
    } else if (!(items in lookup)) {
      lookup[items] = 1;
      if (items !== "na" && items !== "null") {
        result.push(items);
      }
    }
  }
  return result;
}

async function getFilterItems(table, param) {
  const result = [];
  const uniques = findUniqueModelValue(param);
  for (let i = 0; i < uniques.length; i++) {
    let tempItem = await DataStore.query(table, uniques[i]);
    if (tempItem !== undefined && param !== 'instep_type') {
      let item = {id: tempItem['id'], name: tempItem['name'] ? tempItem.name : tempItem.item};
      result.push(item);
    } else if (param === 'instep_type') {
      console.log(uniques[i])
    }
  }
  return result;
}

async function loadFilters() {

  if (route.path.includes("osa") && route.path.includes("models")) {

    styles.value = await getFilterItems(DevFootcareStyle, 'styles');
    heights.value = [{id: "laag", name: "laag", value:"laag"}, {id: "hoog", name: "hoog", value:"hoog"}];
    closings.value = await getFilterItems(DevFootcareClosing, 'closings');
    insteps.value = await getFilterItems(DevFootcareInstep, 'instep_type');

    // priceLevels.value = await DataStore.query(FootcarePriceLevel);
    priceLevels.value = await getFilterItems(FootcarePriceLevel, 'priceLevel');
    suppliers.value = await DataStore.query(FootcareSupplier);
    healthcareProviders.value = await DataStore.query(FootcareHealthcareProvider);

    console.log(suppliers.value);

  } else if (route.path.includes("osb") && route.path.includes("models")) {

    colors.value = await getFilterItems(DevFittrColor, 'colors')
    styles.value = await getFilterItems(DevFittrStyle, 'styles')
    heights.value = await getFilterItems(DevFittrHeight, 'height')
    closings.value = await getFilterItems(DevFittrClosing, 'closings')
    brands.value = findUniqueModelValue('brand');

  }
}

async function setActiveModel(model) {
  activeModel.value = model;
  store.state.model = model;
  modelnumber.value = activeModel.value.modelnumber;
  colornumber.value = activeModel.value.colornumber;
}

async function initModalModels(modelID) {
  activeModels.value = [];
  console.log(modelID);
  // const model = await DataStore.query(ActiveModels, c => c.modelID('eq', modelID));
  const model = store.state.models.find(model => model.modelID === modelID);
  slide.value = model.modelID;
  activeModels.value.push(model);
  await setActiveModel(model);
  const modelFamily = await DataStore.query(ActiveModels, c => c.modelnumber('eq', model.modelnumber).colornumber('ne', model.colornumber));
  modelFamily.forEach(modelFamilyItem => {
    activeModels.value.push(modelFamilyItem);
  })
  setOpen(true);
}

const setImageLoadingState = () => {
  setTimeout(() => {
    imageLoadingState.value = true;
  }, 500);
}

const setFilterLoadingState = () => {
  setTimeout(() => {
    filterLoadingState.value = true;
  }, 500);
}



function setFavorite(id) {
  if (document.querySelector('#favo_' + id).classList.value === 'fal fa-heart') {
    document.querySelector('#favo_' + id).classList.remove('fal');
    document.querySelector('#favo_' + id).classList.remove('fa-heart');
    document.querySelector('#favo_' + id).classList.add('fas');
    document.querySelector('#favo_' + id).classList.add('fa-heart');
    favorites.value.push(id);
  } else {
    document.querySelector('#favo_' + id).classList.remove('fas');
    document.querySelector('#favo_' + id).classList.remove('fa-heart');
    document.querySelector('#favo_' + id).classList.add('fal');
    document.querySelector('#favo_' + id).classList.add('fa-heart');
    favorites.value.forEach((favoId, index) => {
      console.log(index);
      if (favoId === id) {
        favorites.value.splice(index, 1);
      }
    })
  }
}

function showFavorites() {
  if (favorites.value.length > 0) {
    activeModels.value = [];
    favorites.value.forEach(async favoId => {
      const model = await DataStore.query(ActiveModels, c => c.modelID('eq', favoId))
      activeModels.value.push(model);
    });
    slide.value = favorites.value[0];
    setActiveModel();
    setOpen(true);
  } else {
    $q.notify({
      color: 'red-5',
      position: 'center',
      textColor: 'white',
      icon: 'fas fa-triangle-exclamation',
      message: 'Er zijn nog geen favorieten geselecteerd!'
    })
  }
}
const networkOptions = ref({})
async function shareModel() {
  networkOptions.value = {network: 'email', name: 'Email', icon: 'far fah fa-lg fa-envelope', color: '#333333'}

  const model = await DataStore.query(ActiveModels, slide.value);
  const modelText = model.modelnumber + " - " + model.colornumber;

  const options = {
    message: modelText, // not supported on some apps (Facebook, Instagram)
    subject: 'Schoenmodellen doorgestuurd via Fittr!', // fi. for email
    files: [store.state.imagePath + model.filename], // an array of filenames either locally or remotely
    url: null,
    chooserTitle: null, // Android only, you can override the default share sheet title
    appPackageName: null, // Android only, you can provide id of the App you want to share with
    iPadCoordinates: null //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
  };

  // await SocialSharing.shareWithOptions(options).then(r => {
  //   console.log(r)
  // }).catch(e => {
  //   console.log(e);
  //   if (e.completed === false && !e.includes('Abort')) {
  //     $q.notify({
  //       color: 'red-5',
  //       position: 'center',
  //       textColor: 'white',
  //       icon: 'fas fa-triangle-exclamation',
  //       message: 'Helaas, uw browser ondersteunt geen mogelijkheden om bestanden te delen!'
  //     })
  //   }
  // })
}

async function shareAllModels() {

  const urls = ref([]);

  activeModels.value.forEach(model => urls.value.push(store.state.imagePath + model.filename));

  const options = {
    message: 'Schoenmodellen doorgestuurd via Fittr!', // not supported on some apps (Facebook, Instagram)
    subject: 'Schoenmodellen doorgestuurd via Fittr!', // fi. for email
    files: [store.state], // an array of filenames either locally or remotely
    url: null,
    chooserTitle: null, // Android only, you can override the default share sheet title
    appPackageName: null, // Android only, you can provide id of the App you want to share with
    iPadCoordinates: null //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
  };
}

const columns = ref([
  {
    id: 'id',
    name: 'name',
    required: true,
    field: row => row.name,
    format: val => `${val}`,
    sortable: true
  }
])

async function orderModel() {
  setOpen(false);
  let params = {
    model: JSON.stringify(activeModel.value)
  }
  await router.push({name: 'Order', params: params});
}
const selectedStyles = ref([]);
const selectedHeight = ref([]);
const selectedClosings = ref([]);
const selectedColors = ref([]);
const selectedInsteps = ref([]);
const selectedPriceLevel = ref([]);
</script>

<template>

  <ion-page mestyle="--offset-bottom: unset">

    <ion-menu :visible="true" side="start" content-id="men" menu-id="men" class="my-custom-menu">
      <ion-header>
        <ion-toolbar color="primary">
          <ion-title>Start Menu</ion-title>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-item>Menu Item</ion-item>
          <ion-item>Menu Item</ion-item>
          <ion-item>Menu Item</ion-item>
          <ion-item>Menu Item</ion-item>
          <ion-item>Menu Item</ion-item>
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-header translucent>
      <ion-toolbar color="primary">

        <ion-buttons slot="start">
          <ion-back-button default-href="/footcare/home">
          </ion-back-button>
          <img class="header-logo" alt="no-image" src="../assets/logo.png">
        </ion-buttons>

        <ion-buttons slot="end" style="color: white;margin-right: 10px;">

          <ion-searchbar animated inputmode="search" @ionChange="searchModels($event.target)"/>

          <ion-button style="margin-right: 20px" @click="showFavorites">
            <i style="margin-right:4px;font-size: 24px;color: white;" class="fa-light fa-heart"></i>
            <ion-badge style="position:absolute;top:0;right:0;margin-right:-3px" v-if="favorites.length > 0"
                       color="secondary">
              {{ favorites.length }}
            </ion-badge>
          </ion-button>
          <ion-menu-toggle menu="men">
            <i class="fa-light ffa-cogs"></i>
          </ion-menu-toggle>
          <ion-menu-toggle>
            <i style="color:white" class="fa-light fa-bars"></i>
          </ion-menu-toggle>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content id="men" ref='modelContainer'
                 :fullscreen="true"
                 style="height:100%;width: 100%">

      <ion-grid slot="fixed"
                style="height:100%;background: #f7f7f7;width:100%">

        <ion-row style="width: 100%;height: 100%;">
          <!--  the side menu  -->
          <ion-col style="width: 100%;height: 100%;padding-left: 6px;padding-right: 6px;overflow-y: auto" size-xs="12"
                   size-sm="3" size-lg="3">

            <ion-grid style="width: 100%;height: 100%;">
              <ion-row id="modelContainer" class="model-grid show"
                       style="height:100%;">
                <ion-col size="12">

                  <ModelPageFilter propName="styles" title="Styles" :items="styles" :model="selectedStyles"
                                   :loading="filterLoadingState"></ModelPageFilter>

                  <ModelPageFilter propName="height" title="Schoen hoogte" :items="heights" :model="selectedHeight"
                                   :loading="filterLoadingState"></ModelPageFilter>

                  <ModelPageFilter propName="closings" title="Sluiting" :items="closings" :model="selectedClosings"
                                   :loading="filterLoadingState"></ModelPageFilter>

                  <ModelPageFilter v-if="route.path.includes('osb')" propName="colors" title="Kleurtinten" :model="selectedColors"
                                   :items="colors"
                                   :loading="filterLoadingState"></ModelPageFilter>

<!--                  <ModelPageFilter v-if="insteps && route.path.includes('osa')" propName="instep_type"-->
<!--                                   title="Inschottype" :items="insteps"-->
<!--                                   :loading="filterLoadingState"></ModelPageFilter>-->

                  <ModelPageFilter v-if="route.path.includes('osa')" propName="priceLevel" :model="selectedPriceLevel"
                                   title="Prijs niveau" :items="[priceLevels,healthcareProviders,suppliers]"
                                   :loading="filterLoadingState"></ModelPageFilter>

<!--                  <ModelPageFilter v-if="route.path.includes('osa')" :radio="true" propName="healthcareProvider" :model="selectedPriceLevel"-->
<!--                                   title="Zorgverzekeraars" :items="healthcareProviders"-->
<!--                                   :loading="filterLoadingState"></ModelPageFilter>-->

<!--                  <ModelPageFilter v-if="route.path.includes('osa')" :radio="true" propName="supplier" :model="selectedPriceLevel"-->
<!--                                   title="Leveranciers" :items="suppliers"-->
<!--                                   :loading="filterLoadingState"></ModelPageFilter>-->

<!--                  <ion-card style="width: calc(100% - 32px);" v-if="route.path.includes('osa')" class="custom-card">-->
<!--                    <ion-card-header style="padding-bottom: 0">-->
<!--                      <ion-card-title style="opacity:0.95" v-if="!filterLoadingState">-->
<!--                        <q-skeleton animation="fade" height="26px" width="120px" type="rect"/>-->
<!--                      </ion-card-title>-->
<!--                      <ion-card-title v-else>-->
<!--                        Prijs niveau-->
<!--                      </ion-card-title>-->
<!--                    </ion-card-header>-->
<!--                    <ion-card-content>-->
<!--                      <template v-if="!filterLoadingState">-->
<!--                        <template v-for="i in 2" :key="i">-->
<!--                          <ion-item style="opacity:0.95" :detail="false">-->
<!--                            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="QRadio"/>-->
<!--                            <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>-->
<!--                          </ion-item>-->
<!--                          <ion-item v-if="i < 2" style="opacity:0.95" :detail="false">-->
<!--                            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="QRadio"/>-->
<!--                            <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>-->
<!--                          </ion-item>-->
<!--                        </template>-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        <template v-for="level in priceLevels" :key="level.value">-->
<!--                          <ion-item :detail="false" :tappable="true" :button="true">-->
<!--                            <q-radio size="lg" @click="filterPriceLevels(level)" v-model="selectedSuppliers"-->
<!--                                     :val="level.value"-->
<!--                                     :label="capitalize(level.label)"-->
<!--                                     :model-value="selectedSuppliers"/>-->
<!--                          </ion-item>-->
<!--                        </template>-->
<!--                      </template>-->
<!--                    </ion-card-content>-->
<!--                  </ion-card>-->

<!--                  <ion-card style="width: calc(100% - 32px);" v-if="route.path.includes('osa')" class="custom-card">-->
<!--                    <ion-card-header style="padding-bottom: 0">-->
<!--                      <ion-card-title style="opacity:0.95" v-if="!filterLoadingState">-->
<!--                        <q-skeleton animation="fade" height="26px" width="160px" type="rect"/>-->
<!--                      </ion-card-title>-->
<!--                      <ion-card-title v-else>-->
<!--                        Zorgverzekeraars-->
<!--                      </ion-card-title>-->
<!--                    </ion-card-header>-->
<!--                    <ion-card-content>-->
<!--                      <template v-if="!filterLoadingState">-->
<!--                        <template v-for="i in 2" :key="i">-->
<!--                          <ion-item style="opacity:0.95" :detail="false">-->
<!--                            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="QRadio"/>-->
<!--                            <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>-->
<!--                          </ion-item>-->
<!--                          <ion-item v-if="i < 2" style="opacity:0.95" :detail="false">-->
<!--                            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="QRadio"/>-->
<!--                            <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>-->
<!--                          </ion-item>-->
<!--                        </template>-->
<!--                      </template>-->
<!--                      <template v-else>-->
<!--                        <template v-for="h in healthcareProviders" :key="h.value">-->
<!--                          <ion-item :detail="false" :tappable="true" :button="true">-->
<!--                            <q-radio size="lg" @click="filterHealthcareAndSuppliers(h)"-->
<!--                                     v-model="selectedSuppliers"-->
<!--                                     :val="h.value"-->
<!--                                     :label="capitalize(h.label)"-->
<!--                                     :model-value="selectedSuppliers"/>-->
<!--                          </ion-item>-->
<!--                        </template>-->
<!--                      </template>-->
<!--                    </ion-card-content>-->
<!--                  </ion-card>-->

<!--                  <ion-card style="width: calc(100% - 32px);" v-if="route.path.includes('osa')" class="custom-card">-->
<!--                    <ion-card-content>-->
<!--                      <ion-list v-if="!filterLoadingState">-->
<!--                        <ion-list-header style="opacity:0.95">-->
<!--                          <q-skeleton type="rect" animation="fade" height="26px" width="80px"/>-->
<!--                        </ion-list-header>-->
<!--                        <template v-for="i in 4" :key="i">-->
<!--                          <ion-item style="opacity:0.95" :detail="false">-->
<!--                            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>-->
<!--                            <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>-->
<!--                          </ion-item>-->
<!--                          <ion-item style="opacity:0.95" v-if="i < 4" :detail="false">-->
<!--                            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>-->
<!--                            <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>-->
<!--                          </ion-item>-->
<!--                        </template>-->
<!--                      </ion-list>-->
<!--                      <ion-list v-else>-->
<!--                        <ion-list-header>Leveranciers</ion-list-header>-->
<!--                        <template v-for="item in suppliers" :key="item.id">-->
<!--                          <ion-item :detail="false">-->
<!--                            <ion-label>{{ item.name }}</ion-label>-->
<!--                            <ion-checkbox slot="start" color="secondary" size="large"-->
<!--                                          :modelValue="selected"-->
<!--                                          @update:modelValue="update($event, item.id)"-->
<!--                                          :val="item.id">-->
<!--                            </ion-checkbox>-->
<!--                          </ion-item>-->
<!--                        </template>-->
<!--                      </ion-list>-->
<!--                    </ion-card-content>-->
<!--                  </ion-card>-->

                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-col>

          <ion-col size-xs="12"
                   size-sm="9"
                   size-lg="9"
                   style="background: #f7f7f7;height: 100%; width:100%;overflow-y: auto">

            <ion-grid style="width: 100%;">

              <ion-row :style="imageLoadingState ? 'z-index:2;opacity:1':'z-index:0;opacity:0'"
                       class="model-grid show"
                       style="background:#f7f7f7;">

                <template v-for="item in store.state.models" :key="item.modelID">
                  <ion-col xs="12" size-sm="6" size-md="6" size-lg="4" size-xl="4">
                    <ion-card style="margin-bottom: 12px" class="custom-card" button>

                      <ion-toolbar style="--background:transparent">
                        <ion-buttons>
                          <ion-button shape="round" @click="setFavorite(item.modelID)"
                                      style="margin-left: 5px;z-index: 750">
                            <i :id="'favo_' + item.modelID"
                               style="opacity:0.65;color:var(--ion-color-secondary);font-size: 26px"
                               class="fal fa-heart"></i>
                          </ion-button>
                        </ion-buttons>
                      </ion-toolbar>

                      <ion-card-content style="padding-top: 0" @click="initModalModels(item.modelID)">

                        <q-intersection
                            transition="scale"
                            once
                            :treshold="1">
                          <ion-item style="--padding-start: 0;z-index: 0">
                            <!--                            <ion-img v-intersection :src="url + item.file_medium_sized"-->
                            <!--                                     alt="`No Pic`"-->
                            <!--                                     class="card-filler"-->
                            <!--                                     style="&#45;&#45;inner-padding-end: 10px;padding-inline-start:0;width:100%;left:0;margin-left: 0;margin-bottom:15px"-->
                            <!--                                     @ionError="$event.target.src = 'https://d1mxpim4y30517.cloudfront.net/modelImagesFittr/' + item.row.file_medium_sized"-->
                            <!--                                     @ionImgDidLoad="setImageLoadingState()"/>-->
                            <q-img no-transition
                                   placeholder-src="../assets/img/blank.png"
                                   no-spinner
                                   :ratio="1"
                                   fit="contain"
                                   class="card-filler rounded-borders"
                                   @load="setImageLoadingState()"
                                   :draggable="false" ref="imgRef"
                                   :src="store.state.imagePath + item.filename"
                                   @error="!$event.target.src.includes('modelImagesFittr') ? $event.target.src = urlWeb + item.filename : 'Hoi'"
                                   alt="No Image"></q-img>
                          </ion-item>
                        </q-intersection>

                        <ion-card-title style="color:var(--ion-color-primary);padding-left:10px;margin-top:5px">
                          <span> Model:
                        {{ item.modelnumber }}
                         </span>
                        </ion-card-title>

                        <ion-card-subtitle style="padding-left: 12px">
                          <span v-if="item.colornumber !== 'na'"> Kleur:
                            {{ item.colornumber }}
                        </span>
                        </ion-card-subtitle>
                        <ion-card-subtitle style="padding-left: 12px">
                          <span v-if="item.price"> Price:
                            {{ item.price }}
                        </span>
                        </ion-card-subtitle>

                      </ion-card-content>
                    </ion-card>
                  </ion-col>
                </template>
              </ion-row>

              <ion-row :style="!imageLoadingState ? 'z-index:2;display:flex':'z-index:0;display:none'"
                       class="model-grid show"
                       style="background:#f7f7f7;">
                <ion-col v-for="i in 30" :key="i"
                         size-xs="12"
                         size-sm="6"
                         size-md="6"
                         size-lg="4"
                         size-xl="4">

                  <ion-card class="custom-card"
                            style="margin-bottom: 12px">
                    <ion-toolbar style="z-index: 500;--background:transparent">

                      <ion-buttons>
                        <ion-button style="margin-left: 5px">
                          <q-skeleton style="opacity: 0.75" animation="fade" width="26px" height="26px" type="rect"/>
                        </ion-button>
                      </ion-buttons>

                    </ion-toolbar>
                    <ion-card-content style="padding-top: 0">
                      <ion-item
                          style="opacity:0.75;margin-top: -13px;--padding-start: 0;--inner-padding-end: 0;width: 100%;margin-bottom: -15px;padding-bottom: 20px;">
                        <img alt="loading"
                             style="opacity:1;position:relative;left:10%;top:0;width:80%;padding-bottom: 50px;"
                             src="../assets/img/blank.png"/>
                        <q-skeleton type="rect"
                                    style="left:10%;position:absolute;margin-top: 30px;opacity:0.75;padding-bottom: 10px;margin-bottom: 22px;"
                                    animation="fade"
                                    height="130px" width="80%"/>
                      </ion-item>
                      <ion-card-header style="opacity:0.95;padding: 1px 10px">
                        <ion-card-title style="margin-top:0;margin-bottom:0">
                          <q-skeleton type="rect" animation="fade" width="70%" height="20px"/>
                        </ion-card-title>
                        <ion-card-subtitle style="margin-top:5px">
                          <q-skeleton type="rect" animation="fade" width="30%" height="14px"/>
                        </ion-card-subtitle>
                      </ion-card-header>
                    </ion-card-content>
                  </ion-card>
                </ion-col>
              </ion-row>

            </ion-grid>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-modal
        :initial-breakpoint="0.9"
        :breakpoints="[0,0.9]"
        :is-open="isOpenRef"
        :swipe-to-close="false"
        :presenting-element="$parent.$refs.ionRouterOutlet">
      <ion-header translucent>
        <ion-toolbar>
          <ion-buttons slot="start"
                       v-if="$q.platform.is.ios || $q.platform.is.android || ($q.platform.is.desktop && $q.platform.is.safari)">
            <ShareNetwork
                network="email"
                :url="store.state.imagePath + store.state.activeModel.filename"
                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                quote="The hot reload is so fast it\'s near instant. - Evan You"
                hashtags="vuejs,vite"
            >
            <ion-button color="tertiary" fill="clear" @click="shareModel"><i class="fa-light fa-share-from-square"
                                                                             style="font-size: 22px;"></i>
            </ion-button>
            </ShareNetwork>
            <ion-button @click="shareAllModels" color="tertiary" fill="clear"><i class="fa-light fa-share-all"
                                                                                 style="font-size: 22px;"></i>
            </ion-button>
          </ion-buttons>
          <ion-buttons slot="end">
            <ion-button @click="setOpen(false)">Close</ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-page class="ion-padding" style="height: 100%">

        <q-carousel
            style="width:100%;height: calc(100% - 115px);background: transparent"
            v-model="slide"
            animated
            arrows
            control-color="grey"
            :draggable="false"
            infinite
            swipeable
            thumbnails
            padding
            transition-next="slide-left"
            transition-prev="slide-right"
            @transition="setActiveModel">

          <template v-for="(item,index) in activeModels" :key="'slide_' + item.modelID">

            <q-carousel-slide :ref="'slide_' + index"
                              :name="item.modelID"
                              :img-src="store.state.imagePath + item.filename">

              <v-zoomer
                  style="width:75%;height: 75%;left: 12.5%;position: absolute;bottom:20%"
                  ref="zoomer"
                  :limitTranslation="true"
                  :max-scale="5"
                  :zoomed.sync="zoomed"
                  :zooming-elastic="false"
                  pivot="image-center">
                <ion-img
                    @ionError="$event.target.src = urlWeb + item.filename"
                    style="object-fit: contain; width: 100%; height: 100%;"
                    :src="store.state.imagePath + item.filename"/>
              </v-zoomer>

            </q-carousel-slide>
          </template>

        </q-carousel>

        <ion-card
            style="position:absolute;display:flex;flex-wrap:wrap;align-content:flex-start;justify-content:space-evenly;bottom:0;height: 200px;left:0;margin:0;width: 100%;">
          <ion-toolbar
              style="padding-left:10px;display:flex;align-items:center;width: 100%;height: 45px;--background: transparent;border-bottom: 1px solid rgba(75,75,93,0.125)">

            <ion-buttons slot="start">
              <ion-button style="margin-right: 6px" tappable size="large" fill="solid" @click="$refs.zoomer.zoomIn()">
                <i
                    class="fa-light fa-magnifying-glass-plus" style="font-size: 16px;padding: 6px;"></i></ion-button>
              <ion-button tappable size="large" fill="solid" @click="$refs.zoomer.zoomOut()"><i
                  class="fa-light fa-magnifying-glass-minus" style="font-size: 16px;padding: 6px"></i></ion-button>
            </ion-buttons>

            <ion-title>
              <ion-label>
                <div class="text-h5 text-weight-bold" style="color:var(--ion-color-primary)">
                  {{ colornumber !== 'na' ? modelnumber + " - " + colornumber : modelnumber }}
                </div>
              </ion-label>
            </ion-title>

            <q-space></q-space>

            <ion-buttons slot="end">
              <ion-button @click="orderModel()"
                          tappable size="large" fill="solid" v-if="contentType === 'osb'"
                          style="--background:var(--q-secondary);margin-right: 15px;opacity: 0.95;--padding-start: 15px;--padding-end: 15px;">
                <ion-label style="font-size: 16px"> Order |</ion-label>
                <i class="fa-light fa-truck-fast" style="font-size:16px;margin-left: 5px"></i>
              </ion-button>
            </ion-buttons>

          </ion-toolbar>

          <q-toolbar style="display: flex;justify-content: center">
          </q-toolbar>
        </ion-card>
      </ion-page>
    </ion-modal>
  </ion-page>
</template>

<style>

.q-carousel .q-carousel__navigation--bottom {
  bottom: 3px;
}

.q-carousel .q-carousel__navigation-inner img {
  height: 70px !important;
  z-index: 1000 !important;
  width: 70px;
}

.q-carousel .q-carousel__thumbnail {
  border-radius: 0;
  opacity: 1;
  display: flex;
  border-top: 2px solid white;
  border-top: 2px solid white;
  border-right: 1px solid rgba(155, 155, 155, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.q-carousel img.q-carousel__thumbnail {
  padding-left: 5px;
  padding-right: 5px;
  width: 80px;
  border-radius: 0;
  opacity: 1;
  border-right: 1px solid rgba(155, 155, 155, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.q-carousel .q-carousel__thumbnail:last-child {
  border-right: 2px solid white;
}

.q-carousel .q-carousel__thumbnail:first-child {
  border-left: 2px solid white;
}

.q-carousel img.q-carousel__thumbnail.q-carousel__thumbnail--active {
  border-top: 2px solid var(--q-secondary);
  border-bottom: 2px solid var(--q-secondary);
  border-left: 2px solid var(--q-secondary);
  border-right: 2px solid var(--q-secondary);
  border-radius: 5px;
  opacity: 0.65;
}

.q-carousel__slide {
  background-image: none !important;
  background-size: 50%;
  background-position: 50%;
  background-repeat: no-repeat;
}

</style>

<style scoped>
ion-modal {
  --backdrop-opacity: 1;
  --background: white;
}

ion-searchbar {
  padding-top: unset;
  padding-bottom: unset;
  height: 66px;
  min-width: 200px;
}

.header-logo {
  height: 40px;
  margin: 13px 10px 13px 25px;
}

ion-grid {
  padding-top: 0;
  padding-bottom: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

ion-content {
  position: absolute;
  --padding-top: calc(66px + env(safe-area-inset-top));
}

ion-col {
  padding-inline-start: 0;
  padding-inline-end: 0;
}

ion-card {
  /*margin: 12px;*/
}

ion-card-title {
  font-size: 22px;
}

.model-grid {
  padding-top: calc(66px + var(--ion-safe-area-top, 0));
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 0;
  padding-right: 0;
}

.custom-card {
  margin: calc(12px + var(--ion-grid-column-padding-lg, var(--ion-grid-column-padding, 5px))) 12px calc(24px + var(--ion-grid-column-padding-lg, var(--ion-grid-column-padding, 5px)));
}

@media (min-width: 350px) {

}

/*###############################################################*/
@media (min-width: 576px) {

}

/*###############################################################*/
@media (min-width: 768px) {

}

/*###############################################################*/
@media (min-width: 992px) {

}

/*###############################################################*/
@media (min-width: 1024px) {

}

/*###############################################################*/
@media (min-width: 1150px) {

}

</style>
