<script setup>

import {
  menuController,
  useIonRouter,
  IonAvatar,
  IonItem,
  IonList,
  IonButton,
  IonLabel,
  IonAccordion,
  IonAccordionGroup,
  IonMenu,
  IonRouterOutlet,
  IonApp,
  IonText,
    IonSegment,
    IonBadge,
    IonChip,
    IonSpinner,
    IonSegmentButton,
    IonSearchbar
} from "@ionic/vue";

import store from "./store/index.js";

import {Authenticator} from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";

import Amplify, {Auth, DataStore, Hub, Predicates, SortDirection} from 'aws-amplify';
// import Location from "aws-sdk/clients/location";

import awsconfig from './aws-exports';

Amplify.configure(awsconfig);

const router = useIonRouter();

import {ref, provide} from "vue"
import {useQuasar, QSeparator} from "quasar";

import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';
import '@fortawesome/fontawesome-pro/css/light.min.css';
import '@fortawesome/fontawesome-pro/css/thin.min.css';
import '@fortawesome/fontawesome-pro/css/solid.min.css';
import {DevFittrModel, DevFittrOrder} from "@/models";

const currentUser = ref(Auth.currentAuthenticatedUser())

const $q = useQuasar();

provide(
    'store', store
);

function reload() {
  window.location.reload();
}

const editButton = ref(false);
const date = new Date(Date.now());
const currentDate = ref(((date.getDate() > 9) ? date.getDate() : ("0" + date.getDate())) + "-" + ((date.getMonth() > 8) ? (date.getMonth() + 1) : ("0" + (date.getMonth() + 1))) + "-" + date.getFullYear() + " " + ((date.getHours() > 9) ? date.getHours() : ("0" + date.getHours())) + ":" + ((date.getMinutes() > 9) ? date.getMinutes() : ("0" + date.getMinutes())));
const orderDate = ref(currentDate);
const archivedOrders = ref([]);
const submittedOrders = ref([]);

function timeDiffCalc(dateFuture, dateNow) {
  let diffInMilliSeconds = Math.abs(dateFuture - dateNow) / 1000;
  const days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;
  let dagen = 0;
  let dayhours = 0;

  if (days > 0) {
    dayhours = days * 24;
  }

  dagen = hours + dayhours;

  return dagen;
}

function dateCompare(d1, d2) {
  if (timeDiffCalc(new Date(new Date(d2)), Date.now()) < 3) {
    editButton.value = true;
    console.log('NIEW')
  } else {
    editButton.value = false;
    console.log('OUDD')
  }
}

const orderListType = ref();
orderListType.value = "history";
const disabled = ref();
disabled.value = true;

const user = ref();
const logged_in_user = ref();

(async () => {
  try {
    user.value = await Auth.currentAuthenticatedUser();
    logged_in_user.value = user.value.attributes;
    console.log(user.value);

    DataStore.observeQuery(DevFittrOrder, c => c.userName('contains', user.value.attributes.email), {
      sort: s => s.submittedAt(SortDirection.DESCENDING)
    }).subscribe(async snapshot => {
      const {items, isSynced} = snapshot;
      submittedOrders.value = items;
      console.log(`[Snapshot] item count: ${items.length}, isSynced: ${isSynced}`);
      console.log(submittedOrders.value);
    });
  } catch (error) {
    console.log('user is not logged in', error);
  }
})();

const loading = ref(false);

async function getOrders(user) {
  await store.methods.getSubmittedOrders(user);
  await store.methods.getArchivedOrders(user);
  loading.value = true;
}

const accordionGroup = ref();
const closeAccordion = () => {
  if (accordionGroup.value) {
    accordionGroup.value.$el.value = undefined;
  }
}

Hub.listen('auth', async (data) => {
  switch (data.payload.event) {
    case 'signIn':
      console.log('user signed in');
      $q.notify({
        color: 'green-6',
        textColor: 'white',
        icon: 'fal fa-otter',
        message: 'U bent succesvol ingelogd!'
      })
      try {
        user.value = await Auth.currentAuthenticatedUser();
        logged_in_user.value = user.value.attributes;
      } catch (error) {
        console.log('user is not logged in', error);
      }
      await getOrders(user);
      break;
    case 'signUp':
      console.log('user signed up');
      break;
    case 'signOut':
      console.log('user signed out');
      break;
    case 'signIn_failure':
      console.log('user sign in failed');
      break;
    case 'configured':
      console.log('the Auth module is configured');
  }
});
</script>

<template>

  <ion-app>
    <ion-menu :backdrop="true" :visible="true" side="end" content-id="menu" menu-id="menu" class="my-custom-menu">

      <ion-header>
        <ion-toolbar color="primary" style="padding-left: 0;padding-right: 0">
          <ion-buttons slot="start">
            <ion-button @click="menuController.close()">Close</ion-button>
          </ion-buttons>
          <ion-buttons slot="end" style="padding-right: 20px">
            <ion-button @click="() => { router.push('/admin'); menuController.close()}" fill="solid" color="secondary">
              <i style="font-size: 26px;color: rgba(255,255,255,0.9)" class="fa-light fa-tools"></i>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <!--      <ion-content>-->
      <!--        <div id="map" style="height: 500px"></div>-->
      <!--        <ion-accordion-group>-->
      <!--          <ion-accordion value="colors">-->

      <!--            <ion-item button slot="header" style="margin-top: 10px">-->
      <!--              <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center">-->
      <!--                <i class="fa-light fa-user"></i>-->
      <!--              </ion-avatar>-->
      <!--              <ion-label>Account</ion-label>-->
      <!--            </ion-item>-->

      <!--            <ion-list :tappable="true" slot="content">-->

      <!--              &lt;!&ndash;              <amplify-authenticator class="needsclick" :tappable="true">&ndash;&gt;-->
      <!--              &lt;!&ndash;                <div :tappable="true">&ndash;&gt;-->
      <!--              &lt;!&ndash;                  My App&ndash;&gt;-->
      <!--              &lt;!&ndash;                  <amplify-sign-out></amplify-sign-out>&ndash;&gt;-->
      <!--              &lt;!&ndash;                </div>&ndash;&gt;-->
      <!--              &lt;!&ndash;              </amplify-authenticator>&ndash;&gt;-->

      <!--              &lt;!&ndash;                              <authenticator loginMechanisms="['email']" :sign-up-attributes="['given_name', 'family_name', 'phone_number', 'organisation']">&ndash;&gt;-->
      <!--              &lt;!&ndash;                                <template class="needsclick" v-slot="{ user, signOut }">&ndash;&gt;-->
      <!--              &lt;!&ndash;                                  <button @click="signOut">Sign Out</button>&ndash;&gt;-->
      <!--              &lt;!&ndash;                                </template>&ndash;&gt;-->
      <!--              &lt;!&ndash;                              </authenticator>&ndash;&gt;-->

      <!--              <authenticator>-->
      <!--                <template v-slot="{ signOut }">-->
      <!--                  &lt;!&ndash;                    <h1>Hello {{ user.given_name }}!</h1>&ndash;&gt;-->
      <!--                  &lt;!&ndash;                    <ion-label><p>{{ user.attributes.given_name + ' ' + user.attributes.family_name }}</p></ion-label>&ndash;&gt;-->
      <!--                  <button @click="signOut">Sign Out</button>-->
      <!--                </template>-->
      <!--              </authenticator>-->
      <!--              &lt;!&ndash;                              <amplify-auth-container>&ndash;&gt;-->
      <!--              &lt;!&ndash;                                <amplify-authenticator hideSignUp username-alias="email">&ndash;&gt;-->
      <!--              &lt;!&ndash;                                  <amplify-sign-out></amplify-sign-out>&ndash;&gt;-->
      <!--              &lt;!&ndash;                                  <amplify-sign-in slot="sign-in" username-alias="email" hideSignUp></amplify-sign-in>&ndash;&gt;-->
      <!--              &lt;!&ndash;                                  <amplify-confirm-sign-in></amplify-confirm-sign-in>&ndash;&gt;-->
      <!--              &lt;!&ndash;                                  <amplify-confirm-sign-up></amplify-confirm-sign-up>&ndash;&gt;-->
      <!--              &lt;!&ndash;                                  <amplify-require-new-password></amplify-require-new-password>&ndash;&gt;-->
      <!--              &lt;!&ndash;                                  <amplify-verify-contact></amplify-verify-contact>&ndash;&gt;-->
      <!--              &lt;!&ndash;                                  <amplify-totp-setup></amplify-totp-setup>&ndash;&gt;-->
      <!--              &lt;!&ndash;                                  <amplify-sign-up&ndash;&gt;-->
      <!--              &lt;!&ndash;                                      slot="sign-up"&ndash;&gt;-->
      <!--              &lt;!&ndash;                                      username-alias="email"&ndash;&gt;-->
      <!--              &lt;!&ndash;                                  ></amplify-sign-up>&ndash;&gt;-->
      <!--              &lt;!&ndash;                                </amplify-authenticator>&ndash;&gt;-->
      <!--              &lt;!&ndash;                              </amplify-auth-container>&ndash;&gt;-->

      <!--            </ion-list>-->
      <!--          </ion-accordion>-->

      <!--          <ion-accordion>-->
      <!--            <ion-item button slot="header">-->
      <!--              <ion-avatar class="icon-positioning" slot="start">-->
      <!--                <i class="fa-light fa-clipboard-list" style="font-size: 20px"></i>-->
      <!--              </ion-avatar>-->
      <!--              <ion-label>Order menu</ion-label>-->
      <!--            </ion-item>-->
      <!--            <ion-list slot="content" style="border-top: 1px solid var(&#45;&#45;ion-color-medium);">-->
      <!--              <ion-item style="width: 100%;height: 1px"></ion-item>-->
      <!--              <ion-toolbar style="padding-top: 15px; &#45;&#45;background:transparent">-->
      <!--                <ion-segment :value="orderListType" style="width:100%">-->
      <!--                  <ion-segment-button layout="icon-start" value="history" @click="orderListType = 'history'">-->
      <!--                    <i class="fal fa-history" style="margin-right: 5px"></i>-->
      <!--                    <ion-label>Order Historie</ion-label>-->
      <!--                  </ion-segment-button>-->

      <!--                  <ion-segment-button v-if="!(archivedOrders.length > 0)" disabled-->
      <!--                                      layout="icon-start"-->
      <!--                                      style="padding-left:15px;padding-right:15px" value="archived"-->
      <!--                                      @click="orderListType = 'archived'">-->
      <!--                    <i class="fal fa-archive" style="margin-right: 5px"></i>-->
      <!--                    <ion-label>Gearchiveerde Orders</ion-label>-->
      <!--                  </ion-segment-button>-->
      <!--                  <ion-segment-button v-if="archivedOrders.length > 0" layout="icon-start"-->
      <!--                                      style="padding-left:5px;padding-right:5px" value="archived"-->
      <!--                                      @click="orderListType = 'archived'">-->
      <!--                    <i class="fal fa-archive" style="margin-right: 5px"></i>-->
      <!--                    <ion-label>Gearchiveerde Orders</ion-label>-->
      <!--                    <ion-badge color="danger"-->
      <!--                               style="margin-inline-start: 5px;color:white;display: flex;justify-content: center">-->
      <!--                      {{ archivedOrders.length }}-->
      <!--                    </ion-badge>-->
      <!--                  </ion-segment-button>-->
      <!--                </ion-segment>-->
      <!--              </ion-toolbar>-->
      <!--              <ion-toolbar style="padding-top: 20px; &#45;&#45;background:transparent">-->
      <!--                <ion-searchbar style="background:transparent">-->
      <!--                  &lt;!&ndash;                               @ionChange="store.methods.searchSubmittedOrders($event.target.value)"&ndash;&gt;-->
      <!--                </ion-searchbar>-->
      <!--              </ion-toolbar>-->
      <!--              <q-separator style="margin-top: 3px"/>-->
      <!--              &lt;!&ndash;                <ion-content fullscreen style="z-index: 50;">&ndash;&gt;-->
      <!--              <ion-list v-if="loading === true && orderListType === 'history'"-->
      <!--                        style="width:100%;height:100%">-->
      <!--                <ion-card v-for="order in submittedOrders" :key="order.id">-->
      <!--                  {{ dateCompare(orderDate, order.updatedAt) }}-->
      <!--                  &lt;!&ndash;                  {{ ((currentDate.substring(currentDate.length -5, currentDate.length -3)) - 3) > ((order.submittedAt.substring(order.submittedAt.length -5, order.submittedAt.length -3))) ? 'yess' : 'noooo'}}&ndash;&gt;-->
      <!--                  <ion-card-header>-->
      <!--                    <ion-chip color="danger"-->
      <!--                              style="position: relative;right:-65%;margin-bottom:15px;&#45;&#45;color:var(&#45;&#45;ion-color-success);">-->
      <!--                      <i class="fa-light fa-stroopwafel" style="margin-right:5px"></i>-->
      <!--                      {{ order.status }}-->
      <!--                    </ion-chip>-->
      <!--                    <ion-card-title style="font-size:1.5em;width: 100%;color:var(&#45;&#45;ion-color-primary)">-->
      <!--                      &lt;!&ndash;                      {{ t('order_id_one_caption') + ': ' + order.orderIdOne }}&ndash;&gt;-->
      <!--                    </ion-card-title>-->
      <!--                    <ion-card-title style="font-size:1.5em;width: 100%;color:var(&#45;&#45;ion-color-primary)">-->
      <!--                      &lt;!&ndash;                      {{ t('order_id_two_caption') + ': ' + order.orderIdTwo }}&ndash;&gt;-->
      <!--                    </ion-card-title>-->
      <!--                    <ion-card-subtitle style="width: 100%">-->
      <!--                      &lt;!&ndash;                      {{ order.submittedAt }}&ndash;&gt;-->
      <!--                    </ion-card-subtitle>-->
      <!--                    <q-separator style="margin-bottom: 10px"/>-->
      <!--                    <ion-card-subtitle style="width: 100%">-->
      <!--                      &lt;!&ndash;                      {{ "Status: " + order.status }}&ndash;&gt;-->
      <!--                    </ion-card-subtitle>-->
      <!--                    <q-separator style="margin-top: 10px"/>-->
      <!--                  </ion-card-header>-->
      <!--                  <ion-card-content style="margin-bottom:15px">-->
      <!--                    <ion-row>-->
      <!--                      <ion-col size="6" style="display: flex; justify-content: center">-->
      <!--                        <ion-button color="success" fill="solid" style="&#45;&#45;color:white" @click="(() => {-->
      <!--                                    menuController.close('FtrSideMenu').then(() => {-->
      <!--                                     router.push('/print-form/' + order.id)-->
      <!--                                    })})">-->
      <!--                          <ion-label style="color:white"><i class="fa-light fa-memo-pad"-->
      <!--                                                            style="margin-right:5px"></i>Formulier-->
      <!--                          </ion-label>-->
      <!--                        </ion-button>-->
      <!--                      </ion-col>-->
      <!--                      <ion-col size="6" style="display: flex; justify-content: center">-->
      <!--                        <template v-if="editButton = true">-->
      <!--                          <ion-button color="warning" fill="solid" style="&#45;&#45;color:white" @click="(() => {-->
      <!--                                                              menuController.close('FtrSideMenu').then(() => {-->
      <!--                                                               router.push('/order/' + 'edit_submitted_' + order.id)-->
      <!--                                                              })})">-->
      <!--                            <ion-label style="color:white"><i class="fa-light fa-pen-to-square"-->
      <!--                                                              style="margin-right:5px"></i>{{ t('edit_button') }}-->
      <!--                            </ion-label>-->
      <!--                          </ion-button>-->
      <!--                        </template>-->
      <!--                        <template v-else>-->
      <!--                          <ion-button color="warning" fill="solid" style="&#45;&#45;color:white" @click="(() => {-->
      <!--                                                              menuController.close('FtrSideMenu').then(() => {-->
      <!--                                                               router.push('/order/' + 'reorder_' + order.id)-->
      <!--                                                              })})">-->
      <!--                            <ion-label style="color:white"><i class="fa-light fa-clone"-->
      <!--                                                              style="margin-right:5px"></i>{{ t('reorder_button') }}-->
      <!--                            </ion-label>-->
      <!--                          </ion-button>-->
      <!--                        </template>-->
      <!--                      </ion-col>-->
      <!--                    </ion-row>-->
      <!--                  </ion-card-content>-->
      <!--                </ion-card>-->
      <!--              </ion-list>-->
      <!--              <ion-list v-else-if="!submittedOrders.length > 0 && orderListType === 'history'"-->
      <!--                        style="width:100%;height: 100%">-->
      <!--                <ion-spinner style="margin-top:50px;" class="absolute-center"></ion-spinner>-->
      <!--                <ion-label slot="fixed" style="margin: 20px"><h1>Geen order historie</h1></ion-label>-->
      <!--              </ion-list>-->
      <!--              <ion-list v-if="archivedOrders.length > 0 && orderListType === 'archived'"-->
      <!--                        style="width:100%;height:100%">-->
      <!--                <ion-card v-for="order in archivedOrders" :key="order.id">-->
      <!--                  <ion-card-header>-->
      <!--                    <ion-chip>-->
      <!--                      {{ orderListType }}-->
      <!--                    </ion-chip>-->
      <!--                    <ion-card-title style="width: 100%;color:var(&#45;&#45;ion-color-primary)">-->
      <!--                      &lt;!&ndash;                      {{ order.orderIdOne + ' ' + order.orderIdTwo }}&ndash;&gt;-->
      <!--                    </ion-card-title>-->
      <!--                    <ion-card-subtitle style="width: 100%">-->
      <!--                      &lt;!&ndash;                      {{ order.updatedAt }}&ndash;&gt;-->
      <!--                    </ion-card-subtitle>-->
      <!--                    <q-separator style="margin-bottom: 10px"/>-->
      <!--                    <ion-card-subtitle style="width: 100%">-->
      <!--                      &lt;!&ndash;                      {{ "Status: " + order.status }}&ndash;&gt;-->
      <!--                    </ion-card-subtitle>-->
      <!--                    <q-separator style="margin-top: 10px"/>-->
      <!--                  </ion-card-header>-->
      <!--                  <ion-card-content style="margin-bottom:15px">-->
      <!--                    <ion-row>-->
      <!--                      <ion-col size="6" style="display: flex; justify-content: center">-->
      <!--                        <ion-button color="danger" fill="solid" @click="(() => {-->
      <!--                                    menuController.close('FtrSideMenu').then(() => {-->
      <!--                                     router.push('/print-form/' + order.id)-->
      <!--                                    })})">-->
      <!--                          <ion-label style="color:white"><i class="fa-light fa-trash-can" style="margin-right:5px"></i>-->
      <!--                            Verwijder-->
      <!--                          </ion-label>-->
      <!--                        </ion-button>-->
      <!--                      </ion-col>-->
      <!--                      <ion-col size="6" style="display: flex; justify-content: center">-->
      <!--                        <ion-button color="warning" fill="solid" style="&#45;&#45;color:white" @click="(() => {-->
      <!--                                                              menuController.close('FtrSideMenu').then(() => {-->
      <!--                                                               router.push('/order/' + 'order_' + order.id)-->
      <!--                                                              })})">-->
      <!--                          <ion-label style="color:white"><i class="fa-light fa-memo-circle-check"-->
      <!--                                                            style="margin-right:5px"></i>Verder-->
      <!--                          </ion-label>-->
      <!--                        </ion-button>-->
      <!--                      </ion-col>-->
      <!--                    </ion-row>-->
      <!--                  </ion-card-content>-->
      <!--                </ion-card>-->
      <!--              </ion-list>-->
      <!--              <ion-list v-else-if="orderListType === 'archived'"-->
      <!--                        style="width:100%;height:100%">-->
      <!--                <ion-card>-->
      <!--                  <ion-card-content>-->
      <!--                    Leeg-->
      <!--                  </ion-card-content>-->
      <!--                </ion-card>-->
      <!--              </ion-list>-->
      <!--              <ion-card>-->
      <!--                <ion-card-content>-->
      <!--                </ion-card-content>-->
      <!--              </ion-card>-->
      <!--            </ion-list>-->

      <!--            &lt;!&ndash;            <ion-list slot="content" tappable="">&ndash;&gt;-->
      <!--            &lt;!&ndash;              <ion-item>&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                <q-calendar-day&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    ref="calendar"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    v-model="selectedDate"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    view="day"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    bordered&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    :hour24-format="toggled"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    :interval-minutes="15"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    :interval-count="96"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    :interval-height="10"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    animated&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    transition-next="slide-left"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    transition-prev="slide-right"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    @change="onChange"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    @moved="onMoved"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    @click-date="onClickDate"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    @click-time="onClickTime"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    @click-interval="onClickInterval"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    @click-head-intervals="onClickHeadIntervals"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    @click-head-day="onClickHeadDay"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                >&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                  <template #day-container="{ scope: { days }}">&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    <template v-if="hasDate(days)">&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                      <div&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                          class="day-view-current-time-indicator"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                          :style="style"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                      />&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                      <div&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                          class="day-view-current-time-line"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                          :style="style"&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                      />&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                    </template>&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                  </template>&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;&lt;!&ndash;                </q-calendar-day>&ndash;&gt;&ndash;&gt;-->
      <!--            &lt;!&ndash;              </ion-item>&ndash;&gt;-->
      <!--            &lt;!&ndash;            </ion-list>&ndash;&gt;-->
      <!--          </ion-accordion>-->

      <!--        </ion-accordion-group>-->
      <!--        <ion-list :inset="true">-->
      <!--          <ModelPageFilter propName="brands" title="Merk"-->
      <!--                           :items="store.methods.findUniqueModelValue('brands')"-->
      <!--                           :loading="loading"></ModelPageFilter>-->
      <!--        </ion-list>-->
      <!--      </ion-content>-->
      <ion-content :scroll-events="false" style="width:100%;height:100%;position: relative;top:0;bottom:0">
        <ion-list slot="fixed" style="height:100%;width:100%;display: flex;flex-wrap: wrap;justify-content: center;">
          <ion-accordion-group ref="accordionGroup" expand="compact"
                               style="width:90%;margin-left:5%;margin-right:5%;margin-top:15px">
            <ion-accordion v-if="user" value="orders">
              <ion-item ref="orderHistoryContainer" slot="header"
                        button @click="async () => await getOrders()">
                <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">
                  <i class="fa-light fa-clipboard-list" style="font-size: 20px"></i>
                </ion-avatar>
                <ion-label>Order menu</ion-label>
                <!--              <ion-label><p>{{ user.attributes.given_name + ' ' + user.attributes.family_name }}</p></ion-label>-->
              </ion-item>

              <ion-list slot="content" style="border-top: 1px solid var(--ion-color-medium);">
                <ion-item style="width: 100%;height: 1px"></ion-item>
                <ion-toolbar style="padding-top: 15px; --background:transparent">
                  <ion-segment :value="orderListType" style="width:100%">
                    <ion-segment-button layout="icon-start" value="history" @click="orderListType = 'history'">
                      <i class="fal fa-history" style="margin-right: 5px"></i>
                      <ion-label>Order historie</ion-label>
                    </ion-segment-button>

                    <ion-segment-button v-if="!(store.state.archivedOrders.length > 0)" disabled
                                        layout="icon-start"
                                        style="padding-left:15px;padding-right:15px" value="archived"
                                        @click="orderListType = 'archived'">
                      <i class="fal fa-archive" style="margin-right: 5px"></i>
                      <ion-label>Opgeslagen orders</ion-label>
                    </ion-segment-button>
                    <ion-segment-button v-if="store.state.archivedOrders.length > 0" layout="icon-start"
                                        style="padding-left:5px;padding-right:5px" value="archived"
                                        @click="orderListType = 'archived'">
                      <i class="fal fa-archive" style="margin-right: 5px"></i>
                      <ion-label>Opgeslagen orders</ion-label>
                      <ion-badge color="danger"
                                 style="margin-inline-start: 5px;color:white;display: flex;justify-content: center">
                        {{ store.state.archivedOrders.length }}
                      </ion-badge>
                    </ion-segment-button>
                  </ion-segment>
                </ion-toolbar>
                <ion-toolbar style="padding-top: 20px; --background:transparent">
                  <ion-searchbar style="background:transparent"
                                 @ionChange="store.methods.searchSubmittedOrders($event.target.value)"></ion-searchbar>
                </ion-toolbar>
                <q-separator style="margin-top: 3px"/>
                <!--                <ion-content fullscreen style="z-index: 50;">-->
                <ion-list v-if="loading === true && orderListType === 'history'"
                          style="width:100%;height:100%">
                  <ion-card v-for="order in store.state.submittedOrders" :key="order.id">
                    {{ dateCompare(orderDate, order.updatedAt) }}
                    <!--                  {{ ((currentDate.substring(currentDate.length -5, currentDate.length -3)) - 3) > ((order.submittedAt.substring(order.submittedAt.length -5, order.submittedAt.length -3))) ? 'yess' : 'noooo'}}-->
                    <ion-card-header>
                      <ion-chip color="danger"
                                style="position: relative;right:-65%;margin-bottom:15px;--color:var(--ion-color-success);">
                        <i class="fa-light fa-stroopwafel" style="margin-right:5px"></i>
                        {{ order.status }}
                      </ion-chip>
                      <ion-card-title style="font-size:1.5em;width: 100%;color:var(--ion-color-primary)">
                        {{ order.orderIdOne }}
                      </ion-card-title>
                      <ion-card-title style="font-size:1.5em;width: 100%;color:var(--ion-color-primary)">
                        {{ order.orderIdTwo }}
                      </ion-card-title>
                      <ion-card-subtitle style="width: 100%">
                        {{ order.submittedAt }}
                      </ion-card-subtitle>
                      <q-separator style="margin-bottom: 10px"/>
                      <ion-card-subtitle style="width: 100%">
                        {{ "Status: " + order.status }}
                      </ion-card-subtitle>
                      <q-separator style="margin-top: 10px"/>
                    </ion-card-header>
                    <ion-card-content style="margin-bottom:15px">
                      <ion-row>
                        <ion-col size="6" style="display: flex; justify-content: center">
                          <ion-button color="success" fill="solid" style="--color:white" @click="(() => {
                                                    menuController.close('FtrSideMenu').then(() => {
                                                     router.push('/print-form/' + order.id)
                                                    })})">
                            <ion-label style="color:white"><i class="fa-light fa-memo-pad"
                                                              style="margin-right:5px"></i>Formulier
                            </ion-label>
                          </ion-button>
                        </ion-col>
                        <ion-col size="6" style="display: flex; justify-content: center">
                          <template v-if="editButton = true">
                            <ion-button color="warning" fill="solid" style="--color:white" @click="(() => {
                                                                              menuController.close('FtrSideMenu').then(() => {
                                                                               router.push('/order/' + 'edit_submitted_' + order.id)
                                                                              })})">
                              <ion-label style="color:white"><i class="fa-light fa-pen-to-square"
                                                                style="margin-right:5px"></i>Edit
                              </ion-label>
                            </ion-button>
                          </template>
                          <template v-else>
                            <ion-button color="warning" fill="solid" style="--color:white" @click="(() => {
                                                                              menuController.close('FtrSideMenu').then(() => {
                                                                               router.push('/order/' + 'reorder_' + order.id)
                                                                              })})">
                              <ion-label style="color:white"><i class="fa-light fa-clone"
                                                                style="margin-right:5px"></i>Nabestelling
                              </ion-label>
                            </ion-button>
                          </template>
                        </ion-col>
                      </ion-row>
                    </ion-card-content>
                  </ion-card>
                </ion-list>
                <ion-list v-else-if="!store.state.submittedOrders.length > 0 && orderListType === 'history'"
                          style="width:100%;height: 100%">
                  <ion-spinner style="margin-top:50px;" class="absolute-center"></ion-spinner>
                  <ion-label slot="fixed" style="margin: 20px"><h1>Geen historie</h1></ion-label>
                </ion-list>
                <ion-list v-if="store.state.archivedOrders.length > 0 && orderListType === 'archived'"
                          style="width:100%;height:100%">
                  <ion-card v-for="order in store.state.archivedOrders" :key="order.id">
                    <ion-card-header>
                      <ion-chip>
                        {{ orderListType }}
                      </ion-chip>
                      <ion-card-title style="width: 100%;color:var(--ion-color-primary)">
                        {{ order.orderIdOne + ' ' + order.orderIdTwo }}
                      </ion-card-title>
                      <ion-card-subtitle style="width: 100%">
                        {{ order.updatedAt }}
                      </ion-card-subtitle>
                      <q-separator style="margin-bottom: 10px"/>
                      <ion-card-subtitle style="width: 100%">
                        {{ "Status: " + order.status }}
                      </ion-card-subtitle>
                      <q-separator style="margin-top: 10px"/>
                    </ion-card-header>
                    <ion-card-content style="margin-bottom:15px">
                      <ion-row>
                        <ion-col size="6" style="display: flex; justify-content: center">
                          <ion-button color="danger" fill="solid" @click="(() => {
                                                    menuController.close('FtrSideMenu').then(() => {
                                                     router.push('/print-form/' + order.id)
                                                    })})">
                            <ion-label style="color:white"><i class="fa-light fa-trash-can"
                                                              style="margin-right:5px"></i>
                              Verwijder
                            </ion-label>
                          </ion-button>
                        </ion-col>
                        <ion-col size="6" style="display: flex; justify-content: center">
                          <ion-button color="warning" fill="solid" style="--color:white" @click="(() => {
                                                                              menuController.close('FtrSideMenu').then(() => {
                                                                               router.push('/order/' + 'order_' + order.id)
                                                                              })})">
                            <ion-label style="color:white"><i class="fa-light fa-memo-circle-check"
                                                              style="margin-right:5px"></i>Verder
                            </ion-label>
                          </ion-button>
                        </ion-col>
                      </ion-row>
                    </ion-card-content>
                  </ion-card>
                </ion-list>
                <ion-list v-else-if="orderListType === 'archived'"
                          style="width:100%;height:100%">
                  <ion-card>
                    <ion-card-content>
                      none
                    </ion-card-content>
                  </ion-card>
                </ion-list>
                <ion-card>
                  <ion-card-content>
                  </ion-card-content>
                </ion-card>
              </ion-list>
              <q-separator style="margin-top: 20px"/>
            </ion-accordion>
            <ion-accordion value="colors">

              <ion-item slot="header" button>
                <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">
                  <i class="fa-light fa-circle-user" style="font-size: 20px"></i>
                </ion-avatar>
                <ion-label>Account</ion-label>
                <!--              <ion-label><p>{{ user.attributes.given_name + ' ' + user.attributes.family_name }}</p></ion-label>-->
              </ion-item>

              <ion-item slot="content" lines="none"
                        style="--padding-inline-start:0;--padding-inline-end:0;--padding-start: 0;--padding:0;--border-width: 0.15px 0px 0px 0px;--border-style:solid;--border-color:rgba(55,55,55,0.2);">

                <authenticator :login-mechanisms="['email']">
                  <template v-slot="{ signOut }">
                    <div style="width:100%;padding-top:10px;display:flex;justify-content:center;align-items:center">
                      <ion-button v-if="logged_in_user" capitalize color="danger" size="lg" @click="signOut">
                        <ion-text style="font-size: 16px"><i class="fa-light fa-sign-out"
                                                             style="font-size: 16px;margin-right:5px"></i>|
                        </ion-text>
                        <ion-text style="font-size: 16px;margin-left:5px">Uitloggen</ion-text>
                      </ion-button>
                    </div>
                  </template>
                </authenticator>

              </ion-item>

            </ion-accordion>
            <!--            <ion-accordion value="language">-->
            <!--              <ion-item slot="header" lines="full">-->
            <!--                <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">-->
            <!--                  <i class="fa-light fa-map-marked" style="font-size: 20px"></i>-->
            <!--                </ion-avatar>-->
            <!--                <ion-label>{{ t('languageSettingsCaption') }}</ion-label>-->
            <!--                &lt;!&ndash;              <ion-label><p>{{ user.attributes.given_name + ' ' + user.attributes.family_name }}</p></ion-label>&ndash;&gt;-->
            <!--              </ion-item>-->

            <!--              <ion-list slot="content" style="width: 80%;margin-left: 10%;margin-bottom:10px;">-->
            <!--                <ion-item button lines="full" @click="setLanguage('nl')">-->
            <!--                  <ion-avatar slot="start"><img alt="" src="./assets/nl.png"></ion-avatar>-->
            <!--                  <ion-label>-->
            <!--                    Nederlands-->
            <!--                  </ion-label>-->
            <!--                  <i v-if="i18n.locale === 'nl'" slot="end" class="fal fa-check"></i>-->
            <!--                </ion-item>-->
            <!--                <ion-item button lines="full" @click="setLanguage('de')">-->
            <!--                  <ion-avatar slot="start"><img alt="nopic" src="./assets/de.png"></ion-avatar>-->
            <!--                  <ion-label>-->
            <!--                    Deutsch-->
            <!--                  </ion-label>-->
            <!--                  <i v-if="i18n.locale === 'de'" slot="end" class="fal fa-check"></i>-->
            <!--                </ion-item>-->
            <!--                <ion-item button lines="full" @click="setLanguage('en')">-->
            <!--                  <ion-avatar slot="start"><img alt="nopic" src="./assets/en.png"></ion-avatar>-->
            <!--                  <ion-label>-->
            <!--                    English-->
            <!--                  </ion-label>-->
            <!--                  <i v-if="i18n.locale === 'en'" slot="end" class="fal fa-check"></i>-->
            <!--                </ion-item>-->
            <!--              </ion-list>-->
            <!--            </ion-accordion>-->
            <ion-accordion style="border-bottom: solid 0.15px rgba(25,25,25,0.25)" value="help">
              <ion-item slot="header"
                        button>
                <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">
                  <i class="fa-light fa-comments-question-check" style="font-size: 20px"></i>
                </ion-avatar>
                <ion-label>
                  Help
                </ion-label>
              </ion-item>

              <ion-list slot="content" style="width: 80%;margin-left: 10%;margin-bottom:10px;">
                <ion-item button lines="full">
                  <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">
                    <i class="fal fa-arrow-circle-right" style="font-size: 20px"></i>
                  </ion-avatar>
                  <ion-button @click="() => reload()">Restart</ion-button>
                </ion-item>
                <ion-item button lines="full">
                  <ion-avatar slot="start" style="display: flex;justify-content: center;align-items: center;">
                    <i class="fal fa-code-branch" style="font-size: 20px"></i>
                  </ion-avatar>
                  <ion-label>
                    Version: beta 4.9
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-accordion>
          </ion-accordion-group>

          <!--          <div style="width:100%;height:140px;display:flex;justify-content:center;align-items:center">-->
          <!--            <ion-button v-if="user" capitalize color="warning" @click="() => { menuController.close('FtrSideMenu').then(() => {router.push('/order/custom')})}">-->
          <!--              <ion-text style="font-size: 16px"><i class="fa-light fa-pencil-ruler" style="font-size: 16px;margin-right:5px"></i>|</ion-text><ion-text style="font-size: 16px;margin-left:5px">{{ t('customOrderCaption') }}</ion-text>-->
          <!--            </ion-button>-->
          <!--          </div>-->
          <!--          <ModelPageFilter style="left:unset;height:auto;width:70%;" propName="brands" title="Merk"-->
          <!--                           :items="store.state.brands"-->
          <!--                           :loading="store.state.brands.length > 0"></ModelPageFilter>-->

          <!--          <ModelPageFilter style="left:unset;height:auto;width:70%;" propName="brands" title="Merk"-->
          <!--                           :items="store.state.brands"-->
          <!--                           :loading="store.state.brands.length > 0"></ModelPageFilter>-->
        </ion-list>
      </ion-content>
    </ion-menu>

    <ion-router-outlet id="menu"/>

  </ion-app>

</template>

<style>
.amplify-field-group :not(:first-child) .amplify-input {
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}

[data-amplify-authenticator] [data-amplify-container] {
  position: relative;
  place-self: center;
  width: 25rem !important;
}

IonPage {
  background: white;
}

*, *:before, *:after {
  box-sizing: border-box !important;
}

IonApp {
  height: 100%;
}

.icon-positioning {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-card {
  margin-bottom: calc(24px + var(--ion-grid-column-padding-lg, var(--ion-grid-column-padding, 5px)));
  /*margin-left: 24px;*/
  margin-top: calc(12px + var(--ion-grid-column-padding-lg, var(--ion-grid-column-padding, 5px)));
}
*, *:before, *:after {
  box-sizing: border-box !important;
}


.q-expansion-item--collapsed {
  border-bottom: 1px solid rgba(75, 75, 75, 0.25) !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

.q-expansion-item--expanded {
  border-bottom: 1px solid rgba(75, 75, 75, 0.25) !important;
  padding-top: 5px;
  padding-bottom: 5px;
}

ion-menu {
  --width: 100%;
}

ion-menu ion-content {
  --width: 100%;
}

ion-menu ion-card {
  width: 90%;
  margin-left: 5%;
}

ion-menu ion-card-content {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

[data-amplify-authenticator] {
  --amplify-colors-background-primary: var(--amplify-colors-white);
  --amplify-colors-background-secondary: var(--amplify-colors-neutral-60);
  --amplify-colors-background-tertiary: var(--amplify-colors-neutral-60);
  --amplify-colors-border-primary: var(--amplify-colors-neutral-60);
  --amplify-colors-border-secondary: var(--amplify-colors-neutral-80);
  --amplify-colors-border-tertiary: var(--amplify-colors-neutral-60);
  --amplify-colors-brand-primary-10: var(--amplify-colors-teal-100);
  --amplify-colors-brand-primary-20: var(--amplify-colors-teal-90);
  --amplify-colors-brand-primary-40: var(--amplify-colors-teal-80);
  --amplify-colors-brand-primary-60: var(--amplify-colors-teal-60);
  --amplify-colors-brand-primary-80: var(--amplify-colors-teal-40);
  --amplify-colors-brand-primary-90: var(--amplify-colors-teal-20);
  --amplify-colors-brand-primary-100: var(--amplify-colors-teal-10);
  --amplify-colors-font-interactive: var(--amplify-colors-teal-80);
  --amplify-components-heading-color: var(--amplify-colors-neutral-20);
  --amplify-components-tabs-item-active-border-color: var(--amplify-colors-white);
  --amplify-components-tabs-item-active-color: var(--amplify-colors-neutral-80);
  --amplify-components-tabs-item-color: var(--amplify-colors-white);
  --amplify-components-tabs-item-focus-color: var(--amplify-colors-neutral-80);
  --amplify-components-text-color: var(--amplify-colors-font-interactive);
}

div [data-amplify-container] {
  position: relative;
  place-self: center;
  width: 25rem !important;
}

ion-menu ion-content ion-content {
  padding-top: 0;
}

ion-accordion.accordion-expanding ion-item[slot="header"],
ion-accordion.accordion-expanded ion-item[slot="header"] {
  --color: var(--ion-color-primary);
}

ion-accordion {
  margin: 0 auto;
}

.inner-scroll {
  --backdrop-opacity: 1;
  --background: black;
}

ion-modal {
  --backdrop-opacity: 1;
  --background: black;
}

ion-accordion.accordion-expanding,
ion-accordion.accordion-expanded {
  width: calc(90% + 6%);
  margin: 2% auto;
}

@media (min-width: 350px) {

}

/*###############################################################*/
@media (min-width: 576px) {

}

/*###############################################################*/
@media (min-width: 768px) {
  ion-menu {
    --width: 29rem;
  }

  ion-menu ion-content {
    --width: 29rem;
  }
}

/*###############################################################*/
@media (min-width: 992px) {

}

/*###############################################################*/
@media (min-width: 1024px) {

}

/*###############################################################*/
@media (min-width: 1150px) {

}
</style>