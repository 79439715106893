<script setup>

import {ref, inject} from "vue";
import {QSkeleton, QRadio} from "quasar";
import {
  IonCard,
  IonCardContent,
  IonCheckbox,
  IonList,
  IonListHeader
} from '@ionic/vue'

import {useRoute} from "vue-router";

const store = inject('store');
const content = ref();
const route = useRoute();
const selected = ref([]);
const props = defineProps({
  loading: Boolean,
  items: Array,
  title: String,
  propName: String,
  radio: Boolean,
  model: {required:true},
})


// const filters = {
//   styles: [],
//   height: [],
//   closings: [],
//   priceLevel: [],
//   healthcareProviders: [],
//   suppliers: []
// }
function unique(array, propertyName) {
  return array.filter((e, i) => array.findIndex(a => a[propertyName] === e[propertyName]) === i);
}

function modelExists(arr, modelID) {
  return arr.some(function (el) {
    return el.modelID === modelID;
  });
}

const capitalize = (value) => {
  if (!value) return '';
  value = value.toString();
  return value.charAt(0).toUpperCase() + value.slice(1)
}
const update = (propName, item) => {
  store.methods.filter(propName, item);
}
</script>

<template>
  <ion-card v-if="!radio && propName !== 'priceLevel'" ref="content" class="custom-card">
    <ion-card-content>
      <ion-list v-if="!loading">
        <ion-list-header style="opacity:0.95">
          <q-skeleton type="rect" animation="fade" height="26px" width="80px"/>
        </ion-list-header>
        <template v-for="i in 4" :key="i">
          <ion-item style="opacity:0.95" :detail="false">
            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
            <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>
          </ion-item>
          <ion-item style="opacity:0.95" v-if="i < 4" :detail="false">
            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
            <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>
          </ion-item>
        </template>
      </ion-list>
      <ion-list v-else>
        <ion-list-header>{{ title }}</ion-list-header>
        <template v-for="item in items" :key="route.path.includes('osb') ? item.id : item.id">
          <ion-item :detail="false">
            <ion-label>{{
                route.path.includes('osb') && (propName !== 'brands' || propName !== 'heights') ? capitalize(item.name) : capitalize(item.name) || capitalize(item.item)
              }}
            </ion-label>
            <ion-checkbox slot="start" color="secondary" size="large"
                          :model-value="selected"
                          @update:modelValue="update(propName, item)"
                          :val="item.id || item">
            </ion-checkbox>
          </ion-item>
        </template>
      </ion-list>
    </ion-card-content>
  </ion-card>

  <ion-card v-if="radio && propName !== 'priceLevel'" ref="content" class="custom-card">
    <ion-card-content>
      <ion-list v-if="!loading">
        <ion-list-header style="opacity:0.95">
          <q-skeleton type="rect" animation="fade" height="26px" width="80px"/>
        </ion-list-header>
        <template v-for="i in 4" :key="i">
          <ion-item style="opacity:0.95" :detail="false">
            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
            <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>
          </ion-item>
          <ion-item style="opacity:0.95" v-if="i < 4" :detail="false">
            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
            <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>
          </ion-item>
        </template>
      </ion-list>
      <ion-list v-else>
        <ion-list-header v-if="items.length > 0">{{ title }}</ion-list-header>
        <template v-for="item in items" :key="item.id">
          <ion-item v-if="items.length > 0" :detail="false">
              <q-radio size="lg" v-model="model"
                       :val="item.id"
                       @update:modelValue="update(propName, item)"
                       :label="capitalize(item.item)"/>
          </ion-item>
        </template>
      </ion-list>
    </ion-card-content>
  </ion-card>

  <ion-card v-if="route.path.includes('osa') && propName === 'priceLevel'" ref="content" class="custom-card">
    <ion-card-content>
      <ion-list v-if="!loading">
        <ion-list-header style="opacity:0.95">
          <q-skeleton type="rect" animation="fade" height="26px" width="80px"/>
        </ion-list-header>
        <template v-for="i in 4" :key="i">
          <ion-item style="opacity:0.95" :detail="false">
            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
            <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>
          </ion-item>
          <ion-item style="opacity:0.95" v-if="i < 4" :detail="false">
            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
            <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>
          </ion-item>
        </template>
      </ion-list>
      <ion-list v-else>
        <ion-list-header v-if="items[0].length > 0">{{ title }}</ion-list-header>
        <template v-for="item in items[0]" :key="item.id">
          <ion-item v-if="items[0].length > 0" :detail="false">
            <q-radio size="lg" v-model="model"
                     :val="item.id"
                     @update:modelValue="update(propName, item)"
                     :label="capitalize(item.item)"
                     :model-value="model"/>
          </ion-item>
        </template>
      </ion-list>
    </ion-card-content>
    <ion-card-content>
      <ion-list v-if="!loading">
        <ion-list-header style="opacity:0.95">
          <q-skeleton type="rect" animation="fade" height="26px" width="80px"/>
        </ion-list-header>
        <template v-for="i in 4" :key="i">
          <ion-item style="opacity:0.95" :detail="false">
            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
            <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>
          </ion-item>
          <ion-item style="opacity:0.95" v-if="i < 4" :detail="false">
            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
            <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>
          </ion-item>
        </template>
      </ion-list>
      <ion-list v-else>
        <ion-list-header v-if="items[1].length > 0">{{ title }}</ion-list-header>
        <template v-for="item in items[1]" :key="item.id">
          <ion-item v-if="items[1].length > 0" :detail="false">
            <q-radio size="lg" v-model="model"
                     :val="item.id"
                     @update:modelValue="update(propName, item)"
                     :label="capitalize(item.item)"
                     :model-value="model"/>
          </ion-item>
        </template>
      </ion-list>
    </ion-card-content>
    <ion-card-content>
      <ion-list v-if="!loading">
        <ion-list-header style="opacity:0.95">
          <q-skeleton type="rect" animation="fade" height="26px" width="80px"/>
        </ion-list-header>
        <template v-for="i in 4" :key="i">
          <ion-item style="opacity:0.95" :detail="false">
            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
            <q-skeleton animation="fade" style="margin-left: 2px" width="70px" type="QBadge"/>
          </ion-item>
          <ion-item style="opacity:0.95" v-if="i < 4" :detail="false">
            <q-skeleton animation="fade" style="margin: 12px" size="26px" type="rect"/>
            <q-skeleton animation="fade" style="margin-left: 2px" width="50px" type="QBadge"/>
          </ion-item>
        </template>
      </ion-list>
      <ion-list v-else>
        <ion-list-header v-if="items[2].length > 0">{{ title }}</ion-list-header>
        <template v-for="item in items[2]" :key="item.id">
          <ion-item v-if="items[2].length > 0" :detail="false">
            <q-radio size="lg" v-model="model"
                     :val="item.id"
                     @update:modelValue="update(propName, item)"
                     :label="capitalize(item.item)"
                     :model-value="model"/>
          </ion-item>
        </template>
      </ion-list>
    </ion-card-content>
  </ion-card>

</template>

<style scoped>
.custom-card {
  margin: 12px;
}
</style>